import { createAction } from "redux-actions";

export namespace IAccountActions {
  export enum Type {
    // SET__USER = 'SET__USER',

    GET__USER__INIT = "GET__USER__INIT",
    GET__USER__SUCCESS = "GET__USER__SUCCESS",
    GET__USER__FAILURE = "GET__USER__FAILURE",

    GET__PROVIDER_CONFIG__INIT = "GET__PROVIDER_CONFIG__INIT",
    GET__PROVIDER_CONFIG__SUCCESS = "GET__PROVIDER_CONFIG__SUCCESS",
    GET__PROVIDER_CONFIG__FAILURE = "GET__PROVIDER_CONFIG__FAILURE",

    GET__USERNAME_AVAILABLE__INIT = "GET__USERNAME_AVAILABLE__INIT",
    GET__USERNAME_AVAILABLE__SUCCESS = "GET__USERNAME_AVAILABLE__SUCCESS",
    GET__USERNAME_AVAILABLE__FAILURE = "GET__USERNAME_AVAILABLE__FAILURE",

    PATCH__UPDATE_USER__INIT = "PATCH__UPDATE_USER__INIT",
    PATCH__UPDATE_USER__SUCCESS = "PATCH__UPDATE_USER__SUCCESS",
    PATCH__UPDATE_USER__FAILURE = "PATCH__UPDATE_USER__FAILURE",

    DELETE__USER_LOGOUT__INIT = "DELETE__USER_LOGOUT__INIT",
    DELETE__USER_LOGOUT__SUCCESS = "DELETE__USER_LOGOUT__SUCCESS",
    DELETE__USER_LOGOUT__ERROR = "DELETE__USER_LOGOUT__ERROR",

    GET__GEO_IP__INIT = "GET__GEO_IP__INIT",
    GET__GEO_IP__SUCCESS = "GET__GEO_IP__SUCCESS",
    GET__GEO_IP__FAILURE = "GET__GEO_IP__FAILURE",

    POST__SEND_SIGN_IN_EMAIL__INIT = "POST__SEND_SIGN_IN_EMAIL__INIT",
    POST__SEND_SIGN_IN_EMAIL__SUCCESS = "POST__SEND_SIGN_IN_EMAIL__SUCCESS",
    POST__SEND_SIGN_IN_EMAIL__FAILURE = "POST__SEND_SIGN_IN_EMAIL__FAILURE",

    POST__GET_CREDITS__INIT = "POST__GET_CREDITS__INIT",
    POST__GET_CREDITS__SUCCESS = "POST__GET_CREDITS__SUCCESS",
    POST__GET_CREDITS__FAILURE = "POST__GET_CREDITS__FAILURE",
    RESET__POST_GET_CREDITS = "RESET__POST_GET_CREDITS",

    UPDATE__USER = "UPDATE__USER",
    UPDATE__PROVIDER_CONFIG = "UPDATE__PROVIDER_CONFIG",

    SET__ACCOUNT_POPUP = "SET__ACCOUNT_POPUP",

    SET__ONBOARDING_POPUP = "SET__ONBOARDING_POPUP",

    RESET__PATCH_UPDATE_USER = "RESET__PATCH_UPDATE_USER",

    RESET__USER_NOTIFICATIONS = "RESET__USER_NOTIFICATIONS",
    RESET__USER_PROFILE = "RESET__USER_PROFILE",

    SET__USER_ONBOARDING = "SET__USER_ONBOARDING",
    RESET__USER_ONBOARDING = "RESET__USER_ONBOARDING",

    RESET__POST_SEND_SIGN_IN_EMAIL = "RESET__POST_SEND_SIGN_IN_EMAIL",

    GET__SAVED_CREDIT_CARDS__INIT = "GET__SAVED_CREDIT_CARDS__INIT",
    GET__SAVED_CREDIT_CARDS__SUCCESS = "GET__SAVED_CREDIT_CARDS__SUCCESS",
    GET__SAVED_CREDIT_CARDS__FAILURE = "GET__SAVED_CREDIT_CARDS__FAILURE",

    DELETE__SAVED_CREDIT_CARD__INIT = "DELETE__SAVED_CREDIT_CARD__INIT",
    DELETE__SAVED_CREDIT_CARD__SUCCESS = "DELETE__SAVED_CREDIT_CARD__SUCCESS",
    DELETE__SAVED_CREDIT_CARD__FAILURE = "DELETE__SAVED_CREDIT_CARD__FAILURE",

    GET__SUBSCRIPTIONS__INIT = "GET__SUBSCRIPTIONS__INIT",
    GET__SUBSCRIPTIONS__SUCCESS = "GET__SUBSCRIPTIONS__SUCCESS",
    GET__SUBSCRIPTIONS__FAILURE = "GET__SUBSCRIPTIONS__FAILURE",

    // DELETE__SUBSCRIPTION__INIT = 'DELETE__SUBSCRIPTION__INIT',
    // DELETE__SUBSCRIPTION__SUCCESS = 'DELETE__SUBSCRIPTION__SUCCESS',
    // DELETE__SUBSCRIPTION__FAILURE = 'DELETE__SUBSCRIPTION__FAILURE',

    DELETE__VIDEO_GREETING__INIT = "DELETE__VIDEO_GREETING__INIT",
    DELETE__VIDEO_GREETING__SUCCESS = "DELETE__VIDEO_GREETING__SUCCESS",
    DELETE__VIDEO_GREETING__FAILURE = "DELETE__VIDEO_GREETING__FAILURE",

    SET__MANAGE_SUBSCRIPTIONS_EDITING = "SET__MANAGE_SUBSCRIPTIONS_EDITING",
    TOGGLE__MANAGE_SUBSCRIPTIONS_SUB_SELECTION = "TOGGLE__MANAGE_SUBSCRIPTIONS_SUB_SELECTION",
    TOGGLE__MANAGE_SUBSCRIPTIONS_ALL_SUBS_SELECTION = "TOGGLE__MANAGE_SUBSCRIPTIONS_ALL_SUBS_SELECTION",

    DELETE__SUBSCRIPTIONS__INIT = "DELETE__SUBSCRIPTIONS__INIT",
    DELETE__SUBSCRIPTIONS__SUCCESS = "DELETE__SUBSCRIPTIONS__SUCCESS",
    DELETE__SUBSCRIPTIONS__FAILURE = "DELETE__SUBSCRIPTIONS__FAILURE",

    RESET__DELETE_SUBSCRIPTIONS = "RESET__DELETE_SUBSCRIPTIONS",

    PATCH__USER_RANDOM_AVATAR__INIT = "PATCH__USER_RANDOM_AVATAR__INIT",
    PATCH__USER_RANDOM_AVATAR__SUCCESS = "PATCH__USER_RANDOM_AVATAR__SUCCESS",
    PATCH__USER_RANDOM_AVATAR__FAILURE = "PATCH__USER_RANDOM_AVATAR__FAILURE",

    GET__AUTO_MESSAGES__INIT = "GET__AUTO_MESSAGES__INIT",
    GET__AUTO_MESSAGES__SUCCESS = "GET__AUTO_MESSAGES__SUCCESS",
    GET__AUTO_MESSAGES__FAILURE = "GET__AUTO_MESSAGES__FAILURE",

    POST__AUTO_MESSAGE__INIT = "POST__AUTO_MESSAGE__INIT",
    POST__AUTO_MESSAGE__UPDATE_PROGRESS = "POST__AUTO_MESSAGE__UPDATE_PROGRESS",
    POST__AUTO_MESSAGE__SUCCESS = "POST__AUTO_MESSAGE__SUCCESS",
    POST__AUTO_MESSAGE__FAILURE = "POST__AUTO_MESSAGE__FAILURE",

    DELETE__AUTO_MESSAGE__INIT = "DELETE__AUTO_MESSAGE__INIT",
    DELETE__AUTO_MESSAGE__SUCCESS = "DELETE__AUTO_MESSAGE__SUCCESS",
    DELETE__AUTO_MESSAGE__FAILURE = "DELETE__AUTO_MESSAGE__FAILURE",

    PUT__AUTO_MESSAGES__INIT = "PUT__AUTO_MESSAGES__INIT",
    PUT__AUTO_MESSAGES__SUCCESS = "PUT__AUTO_MESSAGES__SUCCESS",
    PUT__AUTO_MESSAGES__FAILURE = "PUT__AUTO_MESSAGES__FAILURE",

    DELETE__AUTO_MESSAGES__INIT = "DELETE__AUTO_MESSAGES__INIT",
    DELETE__AUTO_MESSAGES__SUCCESS = "DELETE__AUTO_MESSAGES__SUCCESS",
    DELETE__AUTO_MESSAGES__FAILURE = "DELETE__AUTO_MESSAGES__FAILURE",

    RESET__AUTO_MESSAGES = "RESET__AUTO_MESSAGES",

    POST__USER_TAG__INIT = "POST__USER_TAG__INIT",
    POST__USER_TAG__SUCCESS = "POST__USER_TAG__SUCCESS",
    POST__USER_TAG__FAILURE = "POST__USER_TAG__FAILURE",

    GET__MY_PURCHASES__INIT = "GET__MY_PURCHASES__INIT",
    GET__MY_PURCHASES__SUCCESS = "GET__MY_PURCHASES__SUCCESS",
    GET__MY_PURCHASES__FAILURE = "GET__MY_PURCHASES__FAILURE",

    GET__MY_PURCHASES_NEXT__INIT = "GET__MY_PURCHASES_NEXT__INIT",
    GET__MY_PURCHASES_NEXT__SUCCESS = "GET__MY_PURCHASES_NEXT__SUCCESS",
    GET__MY_PURCHASES_NEXT__FAILURE = "GET__MY_PURCHASES_NEXT__FAILURE",

    GET__ID_VERIFICATION__INIT = "GET__ID_VERIFICATION__INIT",
    GET__ID_VERIFICATION__SUCCESS = "GET__ID_VERIFICATION__SUCCESS",
    GET__ID_VERIFICATION__FAILURE = "GET__ID_VERIFICATION__FAILURE",

    GET__VAULT_MEDIA__INIT = "GET__VAULT_MEDIA__INIT",
    GET__VAULT_MEDIA__SUCCESS = "GET__VAULT_MEDIA__SUCCESS",
    GET__VAULT_MEDIA__FAILURE = "GET__VAULT_MEDIA__FAILURE",

    GET__VAULT_MEDIA_NEXT__INIT = "GET__VAULT_MEDIA_NEXT__INIT",
    GET__VAULT_MEDIA_NEXT__SUCCESS = "GET__VAULT_MEDIA_NEXT__SUCCESS",
    GET__VAULT_MEDIA_NEXT__FAILURE = "GET__VAULT_MEDIA_NEXT__FAILURE",

    DELETE__VAULT_MEDIA__INIT = "DELETE_VAULT_MEDIA__INIT",
    DELETE__VAULT_MEDIA__SUCCESS = "DELETE_VAULT_MEDIA__SUCCESS",
    DELETE__VAULT_MEDIA__FAILURE = "DELETE_VAULT_MEDIA__FAILURE",
  }

  export const setUserOnboarding = createAction(Type.SET__USER_ONBOARDING);
  export const resetUserOnboarding = createAction(Type.RESET__USER_ONBOARDING);

  // export const setUser = createAction<any>(Type.SET__USER);

  export const initGetUser = createAction(Type.GET__USER__INIT);
  export const successGetUser = createAction<any>(Type.GET__USER__SUCCESS);
  export const failureGetUser = createAction<any>(Type.GET__USER__FAILURE);

  export const initGetProviderConfig = createAction(
    Type.GET__PROVIDER_CONFIG__INIT
  );
  export const successGetProviderConfig = createAction<any>(
    Type.GET__PROVIDER_CONFIG__SUCCESS
  );
  export const failureGetProviderConfig = createAction<any>(
    Type.GET__PROVIDER_CONFIG__FAILURE
  );

  export const initGetUsernameAvailable = createAction(
    Type.GET__USERNAME_AVAILABLE__INIT
  );
  export const successGetUsernameAvailable = createAction<any>(
    Type.GET__USERNAME_AVAILABLE__SUCCESS
  );
  export const failureGetUsernameAvailable = createAction<any>(
    Type.GET__USERNAME_AVAILABLE__FAILURE
  );

  export const initPatchUpdateUser = createAction<any>(
    Type.PATCH__UPDATE_USER__INIT
  );
  export const successPatchUpdateUser = createAction<any>(
    Type.PATCH__UPDATE_USER__SUCCESS
  );
  export const failurePatchUpdateUser = createAction<any>(
    Type.PATCH__UPDATE_USER__FAILURE
  );

  export const resetPatchUpdateUser = createAction(
    Type.RESET__PATCH_UPDATE_USER
  );

  export const initDeleteUserLogout = createAction(
    Type.DELETE__USER_LOGOUT__INIT
  );
  export const successDeleteUserLogout = createAction<any>(
    Type.DELETE__USER_LOGOUT__SUCCESS
  );
  export const errorDeleteUserLogout = createAction<any>(
    Type.DELETE__USER_LOGOUT__ERROR
  );

  export const initGetGeoIp = createAction(Type.GET__GEO_IP__INIT);
  export const successGetGeoIp = createAction<any>(Type.GET__GEO_IP__SUCCESS);
  export const failureGetGeoIp = createAction<any>(Type.GET__GEO_IP__FAILURE);

  export const initPostSendSignInEmail = createAction(
    Type.POST__SEND_SIGN_IN_EMAIL__INIT
  );
  export const successPostSendSignInEmail = createAction<any>(
    Type.POST__SEND_SIGN_IN_EMAIL__SUCCESS
  );
  export const failurePostSendSignInEmail = createAction<any>(
    Type.POST__SEND_SIGN_IN_EMAIL__FAILURE
  );

  export const initPostGetCredits = createAction<any>(
    Type.POST__GET_CREDITS__INIT
  );
  export const successPostGetCredits = createAction<any>(
    Type.POST__GET_CREDITS__SUCCESS
  );
  export const failurePostGetCredits = createAction<any>(
    Type.POST__GET_CREDITS__FAILURE
  );
  export const resetPostGetCredits = createAction(Type.RESET__POST_GET_CREDITS);

  export const updateUser = createAction<any>(Type.UPDATE__USER);
  export const updateProviderConfig = createAction<any>(
    Type.UPDATE__PROVIDER_CONFIG
  );

  export const resetPostSendSignInEmail = createAction(
    Type.RESET__POST_SEND_SIGN_IN_EMAIL
  );

  export const setAccountPopup = createAction<any>(Type.SET__ACCOUNT_POPUP);

  export const setOnboardingPopup = createAction<any>(
    Type.SET__ONBOARDING_POPUP
  );

  export const resetUpdateUserNotifications = createAction(
    Type.RESET__USER_NOTIFICATIONS
  );
  export const resetUpdateUserProfile = createAction(Type.RESET__USER_PROFILE);

  export const initGetSavedCreditCards = createAction(
    Type.GET__SAVED_CREDIT_CARDS__INIT
  );
  export const successGetSavedCreditCards = createAction<any>(
    Type.GET__SAVED_CREDIT_CARDS__SUCCESS
  );
  export const failureGetSavedCreditCards = createAction<any>(
    Type.GET__SAVED_CREDIT_CARDS__FAILURE
  );

  export const initDeleteSavedCreditCard = createAction(
    Type.DELETE__SAVED_CREDIT_CARD__INIT
  );
  export const successDeleteSavedCreditCard = createAction<any>(
    Type.DELETE__SAVED_CREDIT_CARD__SUCCESS
  );
  export const failureDeleteSavedCreditCard = createAction<any>(
    Type.DELETE__SAVED_CREDIT_CARD__FAILURE
  );

  export const initGetSubscriptions = createAction<any>(
    Type.GET__SUBSCRIPTIONS__INIT
  );
  export const successGetSubscriptions = createAction<any>(
    Type.GET__SUBSCRIPTIONS__SUCCESS
  );
  export const failureGetSubscriptions = createAction<any>(
    Type.GET__SUBSCRIPTIONS__FAILURE
  );

  // export const initDeleteSubscription = createAction(Type.DELETE__SUBSCRIPTION__INIT);
  // export const successDeleteSubscription = createAction<any>(Type.DELETE__SUBSCRIPTION__SUCCESS);
  // export const failureDeleteSubscription = createAction<any>(Type.DELETE__SUBSCRIPTION__FAILURE);

  export const initDeleteVideoGreeting = createAction(
    Type.DELETE__VIDEO_GREETING__INIT
  );
  export const successDeleteVideoGreeting = createAction<any>(
    Type.DELETE__VIDEO_GREETING__SUCCESS
  );
  export const failureDeleteVideoGreeting = createAction<any>(
    Type.DELETE__VIDEO_GREETING__FAILURE
  );

  export const setManageSubscriptionsEditing = createAction<any>(
    Type.SET__MANAGE_SUBSCRIPTIONS_EDITING
  );
  export const toggleManageSubscriptionsSubSelection = createAction<any>(
    Type.TOGGLE__MANAGE_SUBSCRIPTIONS_SUB_SELECTION
  );
  export const toggleManageSubscriptionsAllSubsSelection = createAction(
    Type.TOGGLE__MANAGE_SUBSCRIPTIONS_ALL_SUBS_SELECTION
  );

  export const initDeleteSubscriptions = createAction(
    Type.DELETE__SUBSCRIPTIONS__INIT
  );
  export const successDeleteSubscriptions = createAction<any>(
    Type.DELETE__SUBSCRIPTIONS__SUCCESS
  );
  export const failureDeleteSubscriptions = createAction<any>(
    Type.DELETE__SUBSCRIPTIONS__FAILURE
  );

  export const resetDeleteSubscriptions = createAction(
    Type.RESET__DELETE_SUBSCRIPTIONS
  );

  export const initPatchUserRandomAvatar = createAction(
    Type.PATCH__USER_RANDOM_AVATAR__INIT
  );
  export const successPatchUserRandomAvatar = createAction<any>(
    Type.PATCH__USER_RANDOM_AVATAR__SUCCESS
  );
  export const failurePatchUserRandomAvatar = createAction<any>(
    Type.PATCH__USER_RANDOM_AVATAR__FAILURE
  );

  export const initGetAutoMessages = createAction(
    Type.GET__AUTO_MESSAGES__INIT
  );
  export const successGetAutoMessages = createAction<any>(
    Type.GET__AUTO_MESSAGES__SUCCESS
  );
  export const failureGetAutoMessages = createAction<any>(
    Type.GET__AUTO_MESSAGES__FAILURE
  );

  export const initPostAutoMessage = createAction(
    Type.POST__AUTO_MESSAGE__INIT
  );
  export const updatePostAutoMessageProgress = createAction(
    Type.POST__AUTO_MESSAGE__UPDATE_PROGRESS
  );
  export const successPostAutoMessage = createAction<any>(
    Type.POST__AUTO_MESSAGE__SUCCESS
  );
  export const failurePostAutoMessage = createAction<any>(
    Type.POST__AUTO_MESSAGE__FAILURE
  );

  export const initDeleteAutoMessage = createAction(
    Type.DELETE__AUTO_MESSAGE__INIT
  );
  export const successDeleteAutoMessage = createAction<any>(
    Type.DELETE__AUTO_MESSAGE__SUCCESS
  );
  export const failureDeleteAutoMessage = createAction<any>(
    Type.DELETE__AUTO_MESSAGE__FAILURE
  );

  export const initPutAutoMessages = createAction<any>(
    Type.PUT__AUTO_MESSAGES__INIT
  );
  export const successPutAutoMessages = createAction<any>(
    Type.PUT__AUTO_MESSAGES__SUCCESS
  );
  export const failurePutAutoMessages = createAction<any>(
    Type.PUT__AUTO_MESSAGES__FAILURE
  );

  export const initDeleteAutoMessages = createAction(
    Type.DELETE__AUTO_MESSAGES__INIT
  );
  export const successDeleteAutoMessages = createAction<any>(
    Type.DELETE__AUTO_MESSAGES__SUCCESS
  );
  export const failureDeleteAutoMessages = createAction<any>(
    Type.DELETE__AUTO_MESSAGES__FAILURE
  );

  export const resetAutoMessages = createAction(Type.RESET__AUTO_MESSAGES);

  export const initPostUserTag = createAction<any>(Type.POST__USER_TAG__INIT);
  export const successPostUserTag = createAction<any>(
    Type.POST__USER_TAG__SUCCESS
  );
  export const failurePostUserTag = createAction<any>(
    Type.POST__USER_TAG__FAILURE
  );

  export const initGetMyPurchases = createAction(Type.GET__MY_PURCHASES__INIT);
  export const successGetMyPurchases = createAction<any>(
    Type.GET__MY_PURCHASES__SUCCESS
  );
  export const failureGetMyPurchases = createAction<any>(
    Type.GET__MY_PURCHASES__FAILURE
  );

  export const initGetMyPurchasesNext = createAction(
    Type.GET__MY_PURCHASES_NEXT__INIT
  );
  export const successGetMyPurchasesNext = createAction<any>(
    Type.GET__MY_PURCHASES_NEXT__SUCCESS
  );
  export const failureGetMyPurchasesNext = createAction<any>(
    Type.GET__MY_PURCHASES_NEXT__FAILURE
  );

  export const initGetIdVerification = createAction(
    Type.GET__ID_VERIFICATION__INIT
  );
  export const successGetIdVerification = createAction<any>(
    Type.GET__ID_VERIFICATION__SUCCESS
  );
  export const failureGetIdVerification = createAction<any>(
    Type.GET__ID_VERIFICATION__FAILURE
  );

  export const initGetVaultMedia = createAction(Type.GET__VAULT_MEDIA__INIT);
  export const successGetVaultMedia = createAction<any>(
    Type.GET__VAULT_MEDIA__SUCCESS
  );
  export const failureGetVaultMedia = createAction<any>(
    Type.GET__VAULT_MEDIA__FAILURE
  );

  export const initGetVaultMediaNext = createAction(
    Type.GET__VAULT_MEDIA_NEXT__INIT
  );
  export const successGetVaultMediaNext = createAction<any>(
    Type.GET__VAULT_MEDIA_NEXT__SUCCESS
  );
  export const failureGetVaultMediaNext = createAction<any>(
    Type.GET__VAULT_MEDIA_NEXT__FAILURE
  );

  export const initDeleteVaultMedia = createAction(
    Type.DELETE__VAULT_MEDIA__INIT
  );
  export const successDeleteVaultMedia = createAction<any>(
    Type.DELETE__VAULT_MEDIA__SUCCESS
  );
  export const failureDeleteVaultMedia = createAction<any>(
    Type.DELETE__VAULT_MEDIA__FAILURE
  );
}

export type AccountActions = Omit<typeof IAccountActions, "Type">;
