import { handleActions } from "redux-actions";
import { IHeaderModalActions } from "../common/header-modal/header-modal-actions";
import { IDictionary, IPopupConfig } from "../models";
import {
  ERequestStatus,
  IGetRequest,
  IPostRequest,
  IRequest,
  requestInit,
} from "../utils/async";
import { sendWarningMessage } from "../utils/slack";
import { defined } from "../utils/variable-evaluation";
import { IGetSubscriptionsConfig } from "./account-action-creators";
import { IAccountActions } from "./account-actions";
import {
  EAutoMessageType,
  ESubscriptionInfoStatus,
  IAutoMessageCategory,
  ICreditCard,
  IIdVerificationInfo,
  IMyPurchase,
  IOnboardingConfig,
  IProviderConfig,
  ISubscriptionInfo,
  IUserInfo,
  IVaultMedia,
} from "./account-models";
import { EOnboardingType } from "./onboarding";

export enum EUpdateUserType {
  Notifications = "Notifications",
  Profile = "Profile",
}

export const UPDATE_USER_TYPE = {
  invisible: EUpdateUserType.Profile,
  intro_enabled: EUpdateUserType.Profile,
  marketing: EUpdateUserType.Notifications,
  notifications: EUpdateUserType.Notifications,
  payments: EUpdateUserType.Notifications,
  payouts: EUpdateUserType.Notifications,
};

export interface IAccountModel {
  onboardingConfig: IOnboardingConfig | null;
  user: IUserInfo | null;
  providerConfig: IProviderConfig | null;
  getUser: IGetRequest<any>;
  getProviderConfig: IGetRequest<any>;
  getUsernameAvailable: IGetRequest<any>;
  patchUpdateUser: IPostRequest<any, any>;
  patchUpdateUserNotifications: IPostRequest<any, any>;
  patchUpdateUserProfile: IPostRequest<any, any>;
  getGeoIp: IGetRequest<any>;
  postSendSignInEmail: IPostRequest<any, any>;
  postGetCredits: IPostRequest<any, any>;
  deleteUserLogout: IRequest<any>;
  accountPopup: IPopupConfig | null;
  onboardingPopup: IPopupConfig | null;
  getSavedCreditCards: IGetRequest<any>;
  savedCreditCards: ICreditCard[];
  deleteSavedCreditCard: IRequest<any>;
  getSubscriptions: IGetRequest<any>;
  nextSubscriptionsUrl: string | null;
  subscriptions: ISubscriptionInfo[] | null;
  subscriptionsTotal: number;
  // deleteSubscription: IRequest<any>;
  deleteVideoGreeting: IRequest<any>;
  isManageSubscriptionsEditing: boolean;
  // isManageSubscriptionsSelectAll: boolean;
  manageSubscriptionsSelection: ISubscriptionInfo[];
  deleteSubscriptions: IRequest<any>;
  getRandomAvatar: IGetRequest<any>;
  autoMessageCategories: IAutoMessageCategory[];
  lastSavedAutoMessageCategories: IAutoMessageCategory[];
  getAutoMessages: IGetRequest<any>;
  postAutoMessage: IPostRequest<any, any>;
  deleteAutoMessage: IRequest<any>;
  deleteAutoMessages: IRequest<any>;
  putAutoMessages: IPostRequest<any, any>;
  postUserTag: IPostRequest<any, any>;
  getMyPurchases: IGetRequest<any>;
  getMyPurchasesNext: IGetRequest<any>;
  nextMyPurchasesUrl: string;
  myPurchases: IMyPurchase[];
  getIdVerification: IGetRequest<any>;
  idVerification: IIdVerificationInfo | null;
  getVaultMedia: IGetRequest<any>;
  getVaultMediaNext: IGetRequest<any>;
  nextGetVaultMediaUrl: string;
  vaultMedia: IVaultMedia[];
  deleteVaultMedia: IRequest<any>;
}

const INITIAL_STATE: IAccountModel = {
  onboardingConfig: null,
  user: null,
  providerConfig: null,
  getUser: requestInit(),
  getProviderConfig: requestInit(),
  getUsernameAvailable: requestInit(),
  patchUpdateUser: requestInit(),
  patchUpdateUserNotifications: requestInit(),
  patchUpdateUserProfile: requestInit(),
  getGeoIp: requestInit(),
  postSendSignInEmail: requestInit(),
  postGetCredits: requestInit(),
  deleteUserLogout: requestInit(),
  accountPopup: null,
  onboardingPopup: null,
  getSavedCreditCards: requestInit(),
  savedCreditCards: [],
  deleteSavedCreditCard: requestInit(),
  getSubscriptions: requestInit(),
  nextSubscriptionsUrl: null,
  subscriptions: null,
  subscriptionsTotal: 0,
  // deleteSubscription: requestInit(),
  deleteVideoGreeting: requestInit(),
  isManageSubscriptionsEditing: false,
  // isManageSubscriptionsSelectAll: false,
  manageSubscriptionsSelection: [],
  deleteSubscriptions: requestInit(),
  getRandomAvatar: requestInit(),
  autoMessageCategories: [],
  lastSavedAutoMessageCategories: [],
  getAutoMessages: requestInit(),
  postAutoMessage: requestInit(),
  deleteAutoMessage: requestInit(),
  deleteAutoMessages: requestInit(),
  putAutoMessages: requestInit(),
  postUserTag: requestInit(),
  getMyPurchases: requestInit(),
  getMyPurchasesNext: requestInit(),
  nextMyPurchasesUrl: "",
  myPurchases: [],
  getIdVerification: requestInit(),
  idVerification: null,
  getVaultMedia: requestInit(),
  getVaultMediaNext: requestInit(),
  nextGetVaultMediaUrl: "",
  vaultMedia: [],
  deleteVaultMedia: requestInit(),
};

function patchUpdateUserKey(newValues: IDictionary<any>) {
  const value = Object.keys(newValues)[0];
  let asyncKey = "patchUpdateUser";
  if (defined((UPDATE_USER_TYPE as any)[value])) {
    asyncKey = `${asyncKey}${(UPDATE_USER_TYPE as any)[value]}`;
  }
  return asyncKey;
}

/** Reducer */
export const accountReducer = handleActions<IAccountModel, IAccountModel>(
  {
    [IAccountActions.Type.SET__USER_ONBOARDING]: (
      state,
      action: any
    ): IAccountModel => {
      const newOnboardingConfig = {
        ...state.onboardingConfig,
        ...action.payload.config,
      };
      return {
        ...state,
        onboardingConfig: newOnboardingConfig,
      };
    },
    [IAccountActions.Type.RESET__USER_ONBOARDING]: (
      state,
      action: any
    ): IAccountModel => {
      let newOnboardingConfig: IOnboardingConfig | null = {
        ...state.onboardingConfig,
      };
      if (action.payload.type === EOnboardingType.Init) {
        delete newOnboardingConfig.init;
      } else {
        delete newOnboardingConfig.complete;
      }
      // check if there is any onboarding left
      if (
        !defined(newOnboardingConfig.init) &&
        !defined(newOnboardingConfig.complete)
      ) {
        newOnboardingConfig = null; // reset
      }
      return {
        ...state,
        onboardingConfig: newOnboardingConfig,
      };
    },
    [IAccountActions.Type.RESET__USER_NOTIFICATIONS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        patchUpdateUserNotifications: requestInit(),
      };
    },
    [IAccountActions.Type.RESET__USER_PROFILE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        patchUpdateUserProfile: requestInit(),
      };
    },
    // [IAccountActions.Type.SET__USER]: (state, action: any): IAccountModel => {
    //   return {
    //     ...state,
    //     user: action.payload.user
    //   };
    // },
    [IAccountActions.Type.GET__USER__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      const { isForceLoader } = action.payload;
      return {
        ...state,
        getUser: {
          status: ERequestStatus.Fetching,
        },
        user: isForceLoader ? null : state.user,
      };
    },
    [IAccountActions.Type.GET__USER__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getUser: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        user: action.payload,
      };
    },
    [IAccountActions.Type.GET__USER__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getUser: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__PROVIDER_CONFIG__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getProviderConfig: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__PROVIDER_CONFIG__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const providerConfig: IProviderConfig = action.payload;
      return {
        ...state,
        getProviderConfig: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        providerConfig,
      };
    },
    [IAccountActions.Type.GET__PROVIDER_CONFIG__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getProviderConfig: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__USERNAME_AVAILABLE__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getUsernameAvailable: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__USERNAME_AVAILABLE__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getUsernameAvailable: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.GET__USERNAME_AVAILABLE__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getUsernameAvailable: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.RESET__PATCH_UPDATE_USER]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        patchUpdateUser: requestInit(),
        patchUpdateUserNotifications: requestInit(),
        patchUpdateUserProfile: requestInit(),
      };
    },
    [IAccountActions.Type.PATCH__UPDATE_USER__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        [patchUpdateUserKey(action.payload.newValues)]: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.PATCH__UPDATE_USER__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        [patchUpdateUserKey(action.payload.newValues)]: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        getGeoIp: requestInit(),
        user: action.payload.res, // updating user object here!
        onboardingConfig: null,
      };
    },
    [IAccountActions.Type.PATCH__UPDATE_USER__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        [patchUpdateUserKey(action.payload.newValues)]: {
          error: action.payload.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.POST__GET_CREDITS__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postGetCredits: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.POST__GET_CREDITS__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postGetCredits: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.POST__GET_CREDITS__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postGetCredits: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.RESET__POST_GET_CREDITS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postGetCredits: requestInit(),
      };
    },
    [IAccountActions.Type.POST__SEND_SIGN_IN_EMAIL__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postSendSignInEmail: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.POST__SEND_SIGN_IN_EMAIL__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postSendSignInEmail: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.POST__SEND_SIGN_IN_EMAIL__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postSendSignInEmail: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.RESET__POST_SEND_SIGN_IN_EMAIL]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postSendSignInEmail: requestInit(),
      };
    },
    [IAccountActions.Type.DELETE__USER_LOGOUT__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteUserLogout: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__USER_LOGOUT__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteUserLogout: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        user: null,
      };
    },
    [IAccountActions.Type.DELETE__USER_LOGOUT__ERROR]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteUserLogout: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__GEO_IP__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getGeoIp: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__GEO_IP__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getGeoIp: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.GET__GEO_IP__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getGeoIp: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.SET__ACCOUNT_POPUP]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        accountPopup: action.payload,
      };
    },
    [IAccountActions.Type.SET__ONBOARDING_POPUP]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        onboardingPopup: action.payload,
      };
    },
    [IAccountActions.Type.UPDATE__USER]: (
      state,
      action: any
    ): IAccountModel => {
      const { newValues } = action.payload;
      let user = state.user;

      if (defined(user)) {
        user = { ...user, ...newValues };
      }
      return { ...state, user };
    },
    [IAccountActions.Type.UPDATE__PROVIDER_CONFIG]: (
      state,
      action: any
    ): IAccountModel => {
      const { newValues } = action.payload;
      let providerConfig = state.providerConfig;

      if (defined(providerConfig)) {
        providerConfig = { ...providerConfig, ...newValues };
      }
      return { ...state, providerConfig };
    },
    [IAccountActions.Type.GET__SAVED_CREDIT_CARDS__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getSavedCreditCards: {
          status: ERequestStatus.Fetching,
        },
        savedCreditCards: [],
      };
    },
    [IAccountActions.Type.GET__SAVED_CREDIT_CARDS__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getSavedCreditCards: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        savedCreditCards: action.payload.data,
      };
    },
    [IAccountActions.Type.GET__SAVED_CREDIT_CARDS__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getSavedCreditCards: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.DELETE__SAVED_CREDIT_CARD__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSavedCreditCard: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__SAVED_CREDIT_CARD__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSavedCreditCard: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.DELETE__SAVED_CREDIT_CARD__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSavedCreditCard: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IHeaderModalActions.Type.PATCH__UPDATE_CHAT_FAVORITE__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        user: {
          ...state.user!,
          has_favorites: true,
        },
      };
    },
    [IAccountActions.Type.GET__SUBSCRIPTIONS__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      // const { isInitialRequest } = action.payload.config;
      const nextState = {
        getSubscriptions: {
          status: ERequestStatus.Fetching,
        },
        // subscriptions: isInitialRequest ? null : state.subscriptions
      };
      return {
        ...state,
        ...nextState,
      };
    },
    [IAccountActions.Type.GET__SUBSCRIPTIONS__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const config: IGetSubscriptionsConfig = action.payload.config;
      const res = action.payload.res;

      let subscriptions: ISubscriptionInfo[] = res.data;
      const getSubscriptionsNextUrl: string =
        res.data.length > 0 ? res.next : "";
      const subscriptionsTotal: number = res.total;

      if (!config.isInitialRequest) {
        subscriptions = [...(state.subscriptions || []), ...res.data];
      }

      return {
        ...state,
        nextSubscriptionsUrl: getSubscriptionsNextUrl,
        subscriptions,
        getSubscriptions: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
        subscriptionsTotal,
      };
    },
    [IAccountActions.Type.GET__SUBSCRIPTIONS__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getSubscriptions: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    // [IAccountActions.Type.DELETE__SUBSCRIPTION__INIT]: (state, action: any): IAccountModel => {
    //   return {
    //     ...state,
    //     deleteSubscription: {
    //       status: ERequestStatus.Fetching
    //     }
    //   };
    // },
    // [IAccountActions.Type.DELETE__SUBSCRIPTION__SUCCESS]: (state, action: any): IAccountModel => {
    //   return {
    //     ...state,
    //     deleteSubscription: {
    //       responseData: action.payload.res,
    //       status: ERequestStatus.Fetched
    //     }
    //   };
    // },
    // [IAccountActions.Type.DELETE__SUBSCRIPTION__FAILURE]: (state, action: any): IAccountModel => {
    //   return {
    //     ...state,
    //     deleteSubscription: {
    //       error: action.error,
    //       status: ERequestStatus.Error
    //     }
    //   };
    // },
    [IAccountActions.Type.DELETE__VIDEO_GREETING__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteVideoGreeting: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__VIDEO_GREETING__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteVideoGreeting: {
          responseData: action.payload.res,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.DELETE__VIDEO_GREETING__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteVideoGreeting: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.SET__MANAGE_SUBSCRIPTIONS_EDITING]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        isManageSubscriptionsEditing: action.payload,
        // isManageSubscriptionsSelectAll: false, // reset
        manageSubscriptionsSelection: [], // reset
      };
    },
    [IAccountActions.Type.TOGGLE__MANAGE_SUBSCRIPTIONS_SUB_SELECTION]: (
      state,
      action: any
    ): IAccountModel => {
      const selectedSub: ISubscriptionInfo = action.payload;
      let manageSubscriptionsSelection: ISubscriptionInfo[];
      if (
        state.manageSubscriptionsSelection.findIndex(
          (sub) => sub.user._id === selectedSub.user._id
        ) !== -1
      ) {
        manageSubscriptionsSelection =
          state.manageSubscriptionsSelection.filter(
            (sub) => sub.user._id !== selectedSub.user._id
          );
      } else {
        manageSubscriptionsSelection = [
          ...state.manageSubscriptionsSelection,
          selectedSub,
        ];
      }
      return {
        ...state,
        manageSubscriptionsSelection,
      };
    },
    [IAccountActions.Type.TOGGLE__MANAGE_SUBSCRIPTIONS_ALL_SUBS_SELECTION]: (
      state,
      action: any
    ): IAccountModel => {
      const manageSubscriptionsSelection = (state.subscriptions || []).filter(
        (sub) => sub.status === ESubscriptionInfoStatus.ACTIVE
      );
      return {
        ...state,
        manageSubscriptionsSelection,
        // isManageSubscriptionsSelectAll: !state.isManageSubscriptionsSelectAll,
        // manageSubscriptionsSelection: []
      };
    },
    [IAccountActions.Type.DELETE__SUBSCRIPTIONS__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSubscriptions: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__SUBSCRIPTIONS__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSubscriptions: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        isManageSubscriptionsEditing: false, // reset
        // isManageSubscriptionsSelectAll: false, // reset
        manageSubscriptionsSelection: [], // reset
      };
    },
    [IAccountActions.Type.DELETE__SUBSCRIPTIONS__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSubscriptions: {
          error: action.payload.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.RESET__DELETE_SUBSCRIPTIONS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteSubscriptions: requestInit(),
      };
    },
    [IAccountActions.Type.PATCH__USER_RANDOM_AVATAR__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getRandomAvatar: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.PATCH__USER_RANDOM_AVATAR__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getRandomAvatar: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        user: action.payload || state.user,
      };
    },
    [IAccountActions.Type.PATCH__USER_RANDOM_AVATAR__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getRandomAvatar: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__AUTO_MESSAGES__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getAutoMessages: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__AUTO_MESSAGES__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const autoMessageCategories: IAutoMessageCategory[] = action.payload.data;

      // reporting of missing dimensions
      // (TODO: consider removing this later to improve performance)
      autoMessageCategories.forEach((category) => {
        category.messages.forEach((autoMessage) => {
          if (
            autoMessage.type === EAutoMessageType.Image ||
            autoMessage.type === EAutoMessageType.Video
          ) {
            if (!defined(autoMessage.media)) {
              sendWarningMessage(
                `AutoMessage type \`${autoMessage.type}\` fetched but without media! \`${autoMessage._id}\``
              );
            } else if (
              !defined(autoMessage.media!.width) ||
              !defined(autoMessage.media!.height)
            ) {
              sendWarningMessage(
                `AutoMessage fetched with media but without dimensions! \`${autoMessage._id}\``
              );
            }
          }
        });
      });

      return {
        ...state,
        autoMessageCategories,
        lastSavedAutoMessageCategories: autoMessageCategories, // replace with new
        getAutoMessages: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.GET__AUTO_MESSAGES__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getAutoMessages: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.POST__AUTO_MESSAGE__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, clientSideAutoMessage } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // add clientSideAutoMessage
            return { ...c, messages: [...c.messages, clientSideAutoMessage] };
          }
          return c;
        }),
        postAutoMessage: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.POST__AUTO_MESSAGE__UPDATE_PROGRESS]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, progress, loaded, total, __clientSideSendId } =
        action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // update clientSideAutoMessage with new progress value
            return {
              ...c,
              messages: c.messages.map((m) => {
                if (m.__clientSideSendId === __clientSideSendId) {
                  return {
                    ...m,
                    media: {
                      ...m.media,
                      uploadProgress: progress,
                      loaded,
                      total,
                    },
                  };
                }
                return m;
              }),
            };
          }
          return c;
        }) as any,
      };
    },
    [IAccountActions.Type.POST__AUTO_MESSAGE__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, response, __clientSideSendId } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // replace clientSideAutoMessage with API response
            return {
              ...c,
              messages: c.messages.map((m) => {
                if (m.__clientSideSendId === __clientSideSendId) {
                  return response.data;
                }
                return m;
              }),
            };
          }
          return c;
        }),
        postAutoMessage: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.POST__AUTO_MESSAGE__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, isAbort, errorResponse, __clientSideSendId } =
        action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            if (isAbort) {
              // remove clientSideAutoMessage
              return {
                ...c,
                messages: c.messages.filter(
                  (m) => m.__clientSideSendId !== __clientSideSendId
                ),
              };
            } else {
              // update clientSideAutoMessage
              return {
                ...c,
                messages: c.messages.map((m) => {
                  if (m.__clientSideSendId === __clientSideSendId) {
                    return {
                      ...m,
                      __isUploadFailed: true,
                      __errorMessage:
                        (errorResponse && errorResponse.message) || "",
                    };
                  }
                  return m;
                }),
              };
            }
          }
          return c;
        }),
        postAutoMessage: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.DELETE__AUTO_MESSAGE__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, autoMessageId } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // mark the message to be deleted as __isBeingDeleted
            return {
              ...c,
              messages: c.messages.map((m) => {
                if (m._id === autoMessageId) {
                  return {
                    ...m,
                    __isBeingDeleted: true,
                  };
                }
                return m;
              }),
            };
          }
          return c;
        }),
        deleteAutoMessage: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__AUTO_MESSAGE__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, autoMessageId } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // remove deleted message
            return {
              ...c,
              messages: c.messages.filter((m) => m._id !== autoMessageId),
            };
          }
          return c;
        }),
        deleteAutoMessage: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.DELETE__AUTO_MESSAGE__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, errorResponse, autoMessageId } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            return {
              ...c,
              messages: c.messages.map((m) => {
                if (m._id === autoMessageId) {
                  return {
                    ...m,
                    __isBeingDeleted: false,
                    __errorMessage:
                      (errorResponse && errorResponse.message) || "",
                  };
                }
                return m;
              }),
            };
          }
          return c;
        }),
        deleteAutoMessage: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.PUT__AUTO_MESSAGES__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      const { category } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // mark all messages in this category as __isBeingDeleted
            return {
              ...c,
              messages: c.messages.map((m) => ({
                ...m,
                __isBeingDeleted: true,
              })),
            };
          }
          return c;
        }),
        putAutoMessages: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.PUT__AUTO_MESSAGES__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const { category } = action.payload;
      const autoMessageCategories = state.autoMessageCategories.map((c) => {
        if (c.category === category) {
          // false the loading indicator for all messages in this category (__isBeingDeleted)
          return {
            ...c,
            messages: c.messages.map((m) => ({
              ...m,
              __isBeingDeleted: false,
            })),
          };
        }
        return c;
      });
      return {
        ...state,
        autoMessageCategories,
        lastSavedAutoMessageCategories: autoMessageCategories,
        putAutoMessages: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.PUT__AUTO_MESSAGES__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, errorResponse } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            return {
              ...c,
              messages: c.messages.map((m) => ({
                ...m,
                __isBeingDeleted: false,
                __errorMessage: (errorResponse && errorResponse.message) || "",
              })),
            };
          }
          return c;
        }),
        putAutoMessages: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.DELETE__AUTO_MESSAGES__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      const { category } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            // mark all messages in this category as __isBeingDeleted
            return {
              ...c,
              messages: c.messages.map((m) => ({
                ...m,
                __isBeingDeleted: true,
              })),
            };
          }
          return c;
        }),
        deleteAutoMessages: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__AUTO_MESSAGES__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const { category } = action.payload;
      const autoMessageCategories = state.autoMessageCategories.map((c) => {
        if (c.category === category) {
          // empty this category
          return {
            ...c,
            excerpt: "",
            messages: [],
          };
        }
        return c;
      });
      return {
        ...state,
        autoMessageCategories,
        lastSavedAutoMessageCategories: autoMessageCategories,
        deleteAutoMessages: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.DELETE__AUTO_MESSAGES__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      const { category, errorResponse } = action.payload;
      return {
        ...state,
        autoMessageCategories: state.autoMessageCategories.map((c) => {
          if (c.category === category) {
            return {
              ...c,
              messages: c.messages.map((m) => ({
                ...m,
                __isBeingDeleted: false,
                __errorMessage: (errorResponse && errorResponse.message) || "",
              })),
            };
          }
          return c;
        }),
        deleteAutoMessages: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.RESET__AUTO_MESSAGES]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        autoMessageCategories: state.lastSavedAutoMessageCategories, // replace with old
      };
    },
    [IAccountActions.Type.POST__USER_TAG__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        postUserTag: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.POST__USER_TAG__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      const { tag, response } = action.payload;
      return {
        ...state,
        user:
          state.user === null
            ? null
            : {
                ...state.user,
                tags: [...(state.user.tags || []), tag],
              },
        postUserTag: {
          responseData: response,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IAccountActions.Type.POST__USER_TAG__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      const { errorResponse } = action.payload;
      return {
        ...state,
        postUserTag: {
          error: errorResponse,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__MY_PURCHASES__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getMyPurchases: {
          status: ERequestStatus.Fetching,
        },
        myPurchases: [],
      };
    },
    [IAccountActions.Type.GET__MY_PURCHASES__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getMyPurchases: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        myPurchases: action.payload.data,
        nextMyPurchasesUrl: action.payload.next,
      };
    },
    [IAccountActions.Type.GET__MY_PURCHASES__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getMyPurchases: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__MY_PURCHASES_NEXT__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getMyPurchasesNext: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__MY_PURCHASES_NEXT__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getMyPurchasesNext: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        myPurchases: [...state.myPurchases, ...action.payload.data],
        nextMyPurchasesUrl: action.payload.next,
      };
    },
    [IAccountActions.Type.GET__MY_PURCHASES_NEXT__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getMyPurchasesNext: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__ID_VERIFICATION__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getIdVerification: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__ID_VERIFICATION__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getIdVerification: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        idVerification: action.payload,
      };
    },
    [IAccountActions.Type.GET__ID_VERIFICATION__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getIdVerification: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__VAULT_MEDIA__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getVaultMedia: {
          status: ERequestStatus.Fetching,
        },
        vaultMedia: [],
      };
    },
    [IAccountActions.Type.GET__VAULT_MEDIA__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getVaultMedia: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        vaultMedia: action.payload.data || [],
        nextGetVaultMediaUrl: action.payload.next || "",
      };
    },
    [IAccountActions.Type.GET__VAULT_MEDIA__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getVaultMedia: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.GET__VAULT_MEDIA_NEXT__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getVaultMediaNext: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.GET__VAULT_MEDIA_NEXT__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getVaultMediaNext: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        vaultMedia: [...state.vaultMedia, ...action.payload.data],
        nextGetVaultMediaUrl: action.payload.next,
      };
    },
    [IAccountActions.Type.GET__VAULT_MEDIA_NEXT__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        getVaultMediaNext: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IAccountActions.Type.DELETE__VAULT_MEDIA__INIT]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteVaultMedia: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IAccountActions.Type.DELETE__VAULT_MEDIA__SUCCESS]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteVaultMedia: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        vaultMedia: (state.vaultMedia || []).filter(
          (media) => !action.payload.mediaIds.includes(media._id)
        ),
      };
    },
    [IAccountActions.Type.DELETE__VAULT_MEDIA__FAILURE]: (
      state,
      action: any
    ): IAccountModel => {
      return {
        ...state,
        deleteVaultMedia: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
  },
  INITIAL_STATE
);
