import { IUserInfo } from "../account/account-models";
import { IFileWrapper } from "../common/uploader/uploader-helpers";
import { IMediaType } from "../models";

export interface IGetChat {
  payload: {
    data: IChatInfo[];
    next: string;
    prev: string;
  };
}

export interface IChatInfo {
  available: boolean;
  created_at: string;
  created_under_force_free_chats: boolean;
  credit_rate: number;
  new_rate: number; // the new credit_rate that will be applied after user replies
  credit_rate_updated_at: string;
  intro_ended: boolean;
  is_affiliate_chat: boolean;
  is_free_for_subscriber: boolean;
  is_intro: boolean;
  is_intro_ended?: boolean; //TODO: Check if exists
  is_new_fan?: boolean;
  is_tips_enabled?: boolean;
  last_message_id: string;
  last_message_text: string;
  last_message_time: string;
  other_user: IUserInfo;
  recipient_id: string;
  sender_id: string;
  unread_messages: number;
  unread_tip: boolean | null;
  _id: string;
  updateFn?: () => void;
  favorite?: boolean;
  media_blocked_from?: boolean; // former chat.other_user.media_blocked_from (other user has blocked you from sending media or not enough messages has been exchanged yet)
  is_media_blocked_temporarily?: boolean; // need to exchange more messages (at least 10 unique replies from model)
  media_blocked_for?: boolean; // former chat.other_user.media_blocked_for (you blocked other user from sending you media)
}

// export interface IChatCategories {
//   all?: IChatInfo[];
//   fans?: IChatInfo[];
//   subscribers?: IChatInfo[];
//   favorites?: IChatInfo[];
//   unreplied?: IChatInfo[];
//   recent?: IChatInfo[];
//   vip?: IChatInfo[];
// }

export interface IChatTotals {
  all: number;
  online: number;
  fans: number;
  // followers: number;
  subscribers: number;
  favorites: number;
  unreplied: number;
  vip: number;
  recent: number;
  sent: number;
}

export enum EPatchUpdateChatsAction {
  READ = "read",
  DELETE = "delete",
  HIDE = "hide",
  FAVORITE = "favorite",
  REMOVE_FAVORITE = "remove_favorite",
}

export const MAX_FREE_CHATS_ERROR_MESSAGE =
  "You have hit your maximum amount of free chats, buy a credit package to unlock unlimited free chats.";
export const DENIED_FREE_CHAT_ERROR_MESSAGE =
  "You need to buy a credit package to access chats with this member.";

export enum EBroadcastMessageType {
  Text = "text",
  Image = "image",
  Video = "video",
}

export interface IBroadcastMessage {
  _id?: string;
  type: EBroadcastMessageType;
  text?: string;
  public_id?: string;
  media_id?: string;
  media?: IMediaType;
  mediaFileWrapper?: IFileWrapper | null;
  price?: number;
  price_in_dollars?: boolean; // price in usd instead of Credits
  self_destruct?: boolean;
  __clientSideSendId?: boolean;
  __isUploadFailed?: boolean;
  __errorMessage?: string;
  __isBeingDeleted?: boolean;
  __isVaultMedia?: boolean;
}

export enum EBroadcastStage {
  None = "None",
  Uploading = "Uploading",
  Confirming = "Confirming",
  Broadcasting = "Broadcasting",
  Banned = "Banned",
  Error = "Error",
}
