import { History, Location } from "history";
import React from "react";
import { match } from "react-router";
import {
  getUser,
  updateProviderConfig,
  updateUser,
} from "../account/account-action-creators";
import { AccountActions } from "../account/account-actions";
import {
  EUserType,
  IProviderConfig,
  IUserInfo,
} from "../account/account-models";
import { getPayoutTotals } from "../account/earnings/earnings-action-creators";
import { EarningsActions } from "../account/earnings/earnings-actions";
import {
  getAndAddChatToChatList,
  setBroadcastStage,
  setFilters,
} from "../chats/chats-action-creators";
import { ChatsActions } from "../chats/chats-actions";
import {
  EChatFilterCategory,
  EChatFilterType,
  IChatFilter,
} from "../chats/chats-helpers";
import { EBroadcastStage, IChatInfo } from "../chats/chats-models";
import {
  addClientSideReceiveMessage,
  getFromLastIdMessages,
  updateChatById,
  updateMessageAsPaid,
  updateMessagesRead,
} from "../chats/messenger/messenger-action-creators";
import { MessengerActions } from "../chats/messenger/messenger-actions";
import {
  EMessageType,
  IUpdateMessageAsPaidConfig,
} from "../chats/messenger/messenger-models";
import { IS_BANNED_NAME } from "../clear-cache-unban";
import { ApplicationApprovedPopup } from "../common/application-approved-popup";
import { CryptoPaymentSuccessPopup } from "../common/crypto-payment-success-popup";
import { DiscoverActions } from "../discover/discover-actions";
import { setUnreadFeeds } from "../feed/feed-action-creators";
import { FeedActions } from "../feed/feed-actions";
import { WS_ROOT } from "../globals";
import { EPopupPubnubType, EPopupType, IDictionary } from "../models";
import { IBasicEmptyPopup } from "../popup/basic-empty-popup";
import { popPopup, pushPopup } from "../popup/popup-action-creators";
import { PopupActions } from "../popup/popup-actions";
import {
  addOnlineUser,
  addTypingUser,
  removeOnlineUser,
  removeTypingUser,
  setMessageAlert,
  setPubnubPopup,
  setUnreadMessages,
  setUnreadMessagesFavorites,
} from "../pubnub/pubnub-action-creators";
import { PubnubActions } from "../pubnub/pubnub-actions";
import { isXInArrayOf } from "../utils/array";
import { IPostRequest, isFetching } from "../utils/async";
import { setCookie } from "../utils/cookie";
import { isWebSocketsSupported } from "../utils/detectors";
import { authToken } from "../utils/fetch";
import { MyEvent } from "../utils/my-event";
import { isExactRoute, isRoute } from "../utils/page-and-routing";
import { timeStampInMs, timestampString } from "../utils/time";
import { defined, exists } from "../utils/variable-evaluation";
import { setIsConnected, setIsDisconnected } from "./websocket-action-creators";
import { WebsocketActions } from "./websocket-actions";

function getConnectDelay(attemptNumber: number): number {
  return attemptNumber * 1000;
}

export enum EWebsocketReadyState { // correlates with websocket readyState API status numbers
  Connecting,
  Open,
  Closing,
  Closed,
}

export namespace IWebsocket {
  export interface Props {
    match: match<any>;
    history: History;
    location: Location;
    chats: IChatInfo[];
    chatFiltersSet: IDictionary<IChatFilter>;
    postSendMessage: IPostRequest<any, any>;
    selectedChat?: IChatInfo;
    user: IUserInfo;
    providerConfig: IProviderConfig;
    isConnected: boolean;
    actions: WebsocketActions;
    accountActions: AccountActions;
    pubnubActions: PubnubActions;
    discoverActions: DiscoverActions;
    chatsActions: ChatsActions;
    messengerActions: MessengerActions;
    feedActions: FeedActions;
    earningsActions: EarningsActions;
    popupActions: PopupActions;
  }
  export interface State {}
}

export const myWebsocketEvent = new MyEvent();
const IS_USER_TYPING_TIMEOUT = 10 * 1000; // 10 seconds
const PING_INTERVAL_DELAY = 30 * 1000; // 30 seconds
const PONG_TIMEOUT = 2 * 1000; // 2 seconds

export class Websocket extends React.PureComponent<
  IWebsocket.Props,
  IWebsocket.State
> {
  socket: WebSocket | null = null;
  reconnectTimeout: any;
  reportDisconnectedTimeout: any;
  pingInterval: any;
  pongTimeout: any;
  attemptNumber = 0;
  isUserTypingTimeouts = {};

  public componentDidMount() {
    this.connectSocket();

    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
    window.addEventListener("online", this.handleNetworkStatusChange);
    window.addEventListener("offline", this.handleNetworkStatusChange);

    (window as any)["__wsSendMessage"] = this.handleSendMessage;
  }

  public componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
    window.removeEventListener("online", this.handleNetworkStatusChange);
    window.removeEventListener("offline", this.handleNetworkStatusChange);

    clearTimeout(this.reconnectTimeout);
    clearInterval(this.pingInterval);
    if (
      this.socket &&
      (this.socket.readyState === EWebsocketReadyState.Open ||
        this.socket.readyState === EWebsocketReadyState.Connecting)
    ) {
      this.socket.close();
    }
  }

  private connectSocket = () => {
    if (isWebSocketsSupported) {
      if (document.visibilityState === "visible") {
        if (
          !this.socket ||
          (this.socket.readyState !== EWebsocketReadyState.Connecting &&
            this.socket.readyState !== EWebsocketReadyState.Open)
        ) {
          this.attemptNumber++;
          console.info("connectSocket attempt number: ", this.attemptNumber);

          const token = authToken();
          if (token) {
            this.socket = new WebSocket(`${WS_ROOT}?auth-token=${token}`);
            (window as any)["__ws"] = this.socket;

            this.socket.addEventListener("open", this.handleOpen);
            this.socket.addEventListener("message", this.handleMessageReceived);
            // this.socket.addEventListener("error", this.handleError);
            this.socket.addEventListener("close", this.handleClose); // close is called also after error
          } else {
            this.reconnectSocket();
          }
        } else {
          if (!this.props.isConnected) {
            this.reportConnected();
          }
        }
      } else {
        console.log("connectSocket skipping because app is in background");
      }
    } else {
      console.warn("WebSocket not supported");
    }
  };

  private reconnectSocket = () => {
    this.reconnectTimeout = setTimeout(() => {
      this.connectSocket();
    }, getConnectDelay(this.attemptNumber));
  };

  private forceCloseSocket = () => {
    this.socket?.close();
    // websocket will change into EWebsocketReadyState.Closing state
    // but close event will not get fired yet
    // we are updating state manually here
    // on iOS safari the websocket can even get stuck in EWebsocketReadyState.Closing state
    this.reportDisconnected();
    this.reconnectSocket();
  };

  private handleOpen = (event: Event) => {
    console.info("websocket opened", event);

    this.reportConnected();
    this.attemptNumber = 0; // reset after success
    myWebsocketEvent.addListener(this.handleSendMessage);
    this.keepAlive(); // start sending empty ping messages
    this.reportVisibilityChange(); // update visibility status
  };

  private keepAlive = () => {
    // keep sending empty ping message to keep connection alive even if stale
    this.sendEmptyPingFrame();
    this.pingInterval = setInterval(() => {
      this.sendEmptyPingFrame();
    }, PING_INTERVAL_DELAY);
  };

  private sendEmptyPingFrame = () => {
    myWebsocketEvent.trigger({ action: "ping" });
    this.pongTimeout = setTimeout(() => {
      this.forceCloseSocket();
    }, PONG_TIMEOUT);
  };

  private handleMessageReceived = (event: MessageEvent) => {
    const {
      history,
      location,
      user,
      providerConfig,
      chats,
      chatFiltersSet,
      postSendMessage,
      pubnubActions,
      accountActions,
      chatsActions,
      messengerActions,
    } = this.props;
    const data: any = JSON.parse(event.data);

    console.info("websocket message received", event, JSON.stringify(data));
    window.Rollbar.captureEvent(
      {
        name: "websocket received",
        type: data.type,
        message: JSON.stringify(data),
      },
      "debug"
    );

    switch (data.type) {
      case "init": // establish handshake
        break;
      case "pong": // response to ping
        clearTimeout(this.pongTimeout);
        break;
      case "profile_complete_config":
        let {
          type: _type1,
          action: _action1,
          ...newProfileCompleteConfigValues
        } = data;
        updateUser(
          {
            profile_complete_config: {
              ...user.profile_complete_config,
              ...(newProfileCompleteConfigValues || {}),
            },
          },
          accountActions
        );
        break;
      case "user.update":
        let { type: _type2, action: _action2, ...newUserValues } = data;
        updateUser(newUserValues || {}, accountActions);
        break;
      case "new_message":
        const isMyOwnMessage = data.sender_id === this.props.user._id;
        if (!isMyOwnMessage) {
          // message from someone else

          const clientSideReceiveId = timeStampInMs();
          // const isChatOpen = defined(selectedChat) && data.chat_id === selectedChat._id && isRoute(`/chats/${data.sender_id}`, location);
          const isChatOpen = isRoute(`/chats/${data.sender_id}`, location);
          const isSystemMessage = data.system;

          if (!isSystemMessage) {
            // new message alert
            if (data.unread_messages > 0) {
              setMessageAlert(
                {
                  sender_id: data.sender_id,
                  unread_messages: data.unread_messages,
                },
                pubnubActions
              );
            }

            // message received
            clearTimeout((this.isUserTypingTimeouts as any)[data.sender_id]);
            removeTypingUser(data.sender_id, pubnubActions);
            addOnlineUser(data.sender_id, pubnubActions); // correct, not removing here, just update with add

            if (isChatOpen) {
              // messages updates
              addClientSideReceiveMessage(
                {
                  text: data.text,
                  chat_id: data.chat_id,
                  clientSideReceiveId,
                  sender_id: data.sender_id,
                  recipient_id: data.recipient_id,
                  type: EMessageType.Text,
                },
                messengerActions
              );
              getFromLastIdMessages(
                {
                  last_message_id: data.message_id,
                  chat_id: data.chat_id,
                  // recipient_id: data.recipient_id,
                  // sender_id: data.sender_id,
                  // clientSideReceiveId,
                  actions: messengerActions,
                  chatsActions,
                  accountActions,
                  unread_messages: data.unread_messages,
                },
                messengerActions
              );
            }
          } else {
            // system message
            if (isChatOpen) {
              getFromLastIdMessages(
                {
                  last_message_id: data.message_id,
                  chat_id: data.chat_id,
                  // recipient_id: data.recipient_id,
                  // sender_id: data.sender_id,
                  // clientSideReceiveId,
                  actions: messengerActions,
                  chatsActions,
                  accountActions,
                  unread_messages: data.unread_messages,
                },
                messengerActions
              );
            }
          }

          // chat object updates
          let newChatValues: Partial<IChatInfo> = {};
          if (!isSystemMessage) {
            newChatValues = {
              ...newChatValues,
              unread_messages: data.unread_messages,
              last_message_id: data.message_id,
              last_message_time: timestampString(data.timestamp),
              // sender_id: data.sender_id
            };
          }
          if (defined(data.unread_tip)) {
            newChatValues = { ...newChatValues, unread_tip: data.unread_tip };
          }
          if (defined(data.credit_rate)) {
            newChatValues = {
              ...newChatValues,
              credit_rate: data.credit_rate,
              new_rate: data.new_rate,
            };
          }
          if (defined(data.text)) {
            newChatValues = { ...newChatValues, last_message_text: data.text };
          } else if (defined(data.info)) {
            try {
              const infoText = data.info.split(": ")[1];
              newChatValues = { ...newChatValues, last_message_text: infoText };
            } catch (e) {
              console.error(e);
            }
          }
          if (isXInArrayOf(data.chat_id, chats, "_id")) {
            // update chat if needed
            if (Object.getOwnPropertyNames(newChatValues).length > 0) {
              updateChatById(data.chat_id, newChatValues, messengerActions);
            }
          } else {
            // TODO: we need info from API here if the chat is from current category
            // need something like tags for each chat object
            // right now we will do only for all, unreplied and recent categories
            // and when search is not used
            // because we are sure the chat belongs there
            if (
              (chatFiltersSet[EChatFilterType.Category].value ===
                EChatFilterCategory.All ||
                chatFiltersSet[EChatFilterType.Category].value ===
                  EChatFilterCategory.Unreplied ||
                chatFiltersSet[EChatFilterType.Category].value ===
                  EChatFilterCategory.Recent) &&
              !exists(chatFiltersSet[EChatFilterType.Search].value)
            ) {
              getAndAddChatToChatList(
                data.sender_id,
                chatsActions,
                pubnubActions
              );
            }
          }
        } else {
          // is my own message

          // const isChatOpen = defined(selectedChat) && data.chat_id === selectedChat._id && isRoute(`/chats/${data.sender_id}`, location);
          const isChatOpen = isRoute(`/chats/${data.recipient_id}`, location);

          if (isChatOpen) {
            // messages updates
            // addClientSideSendMessage(
            //   {
            //     text: data.text,
            //     chat_id: data.chat_id,
            //     clientSideSendId,
            //     sender_id: data.sender_id,
            //     recipient_id: data.recipient_id,
            //     type: EMessageType.Text
            //   },
            //   messengerActions
            // );
            if (!isFetching(postSendMessage)) {
              // if we are waiting for a response from postSendMessage then no need to call this
              // this is most likely only used if the message was sent from another client
              // using the same account
              getFromLastIdMessages(
                {
                  last_message_id: data.message_id,
                  chat_id: data.chat_id,
                  // recipient_id: data.recipient_id,
                  // sender_id: data.sender_id,
                  // clientSideReceiveId,
                  actions: messengerActions,
                  chatsActions,
                  accountActions,
                  unread_messages: data.unread_messages,
                },
                messengerActions
              );
            }
          }

          // chat object updates
          let newChatValues: Partial<IChatInfo> = {};
          newChatValues = {
            ...newChatValues,
            unread_messages: data.unread_messages,
            last_message_time: timestampString(data.timestamp),
            // sender_id: data.sender_id
          };
          if (defined(data.credit_rate)) {
            newChatValues = { ...newChatValues, credit_rate: data.credit_rate };
          }
          if (defined(data.new_rate)) {
            newChatValues = { ...newChatValues, new_rate: data.new_rate };
          }
          if (defined(data.text)) {
            newChatValues = { ...newChatValues, last_message_text: data.text };
          } else if (defined(data.info)) {
            try {
              const infoText = data.info.split(": ")[1];
              newChatValues = { ...newChatValues, last_message_text: infoText };
            } catch (e) {
              console.error(e);
            }
          }
          if (isXInArrayOf(data.chat_id, chats, "_id")) {
            // update chat if needed
            if (Object.getOwnPropertyNames(newChatValues).length > 0) {
              updateChatById(data.chat_id, newChatValues, messengerActions);
            }
          } else {
            // TODO: we need info from API here if the chat is from current category
            // need something like tags for each chat object
            // right now we will do only for all category for my own messages
            // and when search is not used
            if (
              chatFiltersSet[EChatFilterType.Category].value ===
                EChatFilterCategory.All &&
              !exists(chatFiltersSet[EChatFilterType.Search].value)
            ) {
              getAndAddChatToChatList(
                data.recipient_id,
                chatsActions,
                pubnubActions
              );
            }
          }
        }
        break;
      case "payout_completed":
        setPubnubPopup(
          {
            type: EPopupPubnubType.PayoutCompleted,
            props: { onClose: () => setPubnubPopup(null, pubnubActions) },
          },
          pubnubActions
        );
        getUser(accountActions);
        break;
      case "typing_start":
        clearTimeout((this.isUserTypingTimeouts as any)[data.sender_id]);
        (this.isUserTypingTimeouts as any)[data.sender_id] = setTimeout(() => {
          // we set a timeout in case there is no 'typing_stop' message received
          removeTypingUser(data.sender_id, pubnubActions);
        }, IS_USER_TYPING_TIMEOUT);
        addTypingUser(data.sender_id, pubnubActions);
        addOnlineUser(data.sender_id, pubnubActions);
        break;
      case "typing_stop":
        clearTimeout((this.isUserTypingTimeouts as any)[data.sender_id]);
        removeTypingUser(data.sender_id, pubnubActions);
        addOnlineUser(data.sender_id, pubnubActions); // correct, not removing here, just update with add
        break;
      case "user_online":
        addOnlineUser(data.sender_id, pubnubActions);
        break;
      case "user_offline":
        removeOnlineUser(data.sender_id, data.last_online, pubnubActions);
        break;
      case "credits_top_up":
        getUser(accountActions);

        // special handling for crypto transaction
        if (data.transaction_id) {
          const cryptoPaymentSuccessPopupProps: IBasicEmptyPopup.Props = {
            children: (
              <CryptoPaymentSuccessPopup
                credits={data.credits}
                paidAmount={data.paid_amount}
                paidCurrency={data.paid_currency}
                fee={data.fee}
              />
            ),
            onClose: () => {
              popPopup(null, this.props.popupActions!);
            },
          };
          pushPopup(
            {
              id: `crypto-payment-success-${data.transaction_id}`,
              type: EPopupType.BasicEmptyPopup,
              props: cryptoPaymentSuccessPopupProps,
            },
            this.props.popupActions!
          );
        }
        break;
      case "featured_enquiry_approved":
      case "model_approved":
      case "onboarding_approved_id":
        const applicationApprovedPopupProps: IBasicEmptyPopup.Props = {
          children: (
            <ApplicationApprovedPopup
              onContinue={() => {
                // popPopup(null, this.props.popupActions!);
                window.location.reload();
              }}
            />
          ),
          onClose: () => {
            // popPopup(null, this.props.popupActions!);
            window.location.reload();
          },
        };
        pushPopup(
          {
            type: EPopupType.BasicEmptyPopup,
            props: applicationApprovedPopupProps,
          },
          this.props.popupActions!
        );
        break;
      case "reported":
        setPubnubPopup(
          {
            type: EPopupPubnubType.AccountTermination,
            props: {
              reason: data.reason,
              onClose: () => setPubnubPopup(null, pubnubActions),
            },
          },
          pubnubActions
        );
        getUser(accountActions);
        break;
      case "total_credits":
        getUser(accountActions);
        //TODO: Show Credits Updated popup using PN message field total_credits
        break;
      case "sale_commissions":
        //TODO: Show Sales Commission popup using PN message field earnings
        break;
      case "banned":
        setCookie(IS_BANNED_NAME, "yes");
        setPubnubPopup(
          {
            type: EPopupPubnubType.AccountTerminated,
            props: {
              reason: data.reason,
              onLogout: () => {
                history.push("/");
                setPubnubPopup(null, pubnubActions);
              },
            },
          },
          pubnubActions
        );
        break;
      case "message_paid":
        const config: IUpdateMessageAsPaidConfig = {
          messageId: data.message_id,
          destructAt: data.destruct_at,
        };
        updateMessageAsPaid(config, messengerActions);
        // update credits
        getUser(accountActions);
        // update earnings totals for model
        if (
          user.type === EUserType.Featured &&
          isExactRoute("/account/earnings/summary", location)
        ) {
          getPayoutTotals(this.props.earningsActions);
        }
        break;
      case "unread_messages":
        setUnreadMessages(data.count, pubnubActions);
        setUnreadMessagesFavorites(data.count_favorites, pubnubActions);
        break;
      case "new_fan":
        getUser(accountActions); // This is to update boostbanner
        // TODO: maybe add back later, this is the more "optimized" way of doing this, problem is it doesnt add an avatar and is expensive to carry info in pubnub
        break;
      case "message_read":
        updateMessagesRead(
          {
            messageIds: data.message_ids,
            chatId: data.chat_id,
            recipientId: data.recipient_id,
            destructibles: data.destructibles,
            timestampString: timestampString(data.timestamp),
          },
          messengerActions
        );
        break;
      case "block_chat_media":
        if (data.chat_id) {
          updateChatById(
            data.chat_id,
            {
              media_blocked_from: true,
            },
            this.props.messengerActions
          );
        }
        break;
      case "allow_chat_media":
        if (data.chat_id) {
          updateChatById(
            data.chat_id,
            {
              media_blocked_from: false,
            },
            this.props.messengerActions
          );
        }
        break;
      case "hide_skinscore_banner":
        updateUser({ show_skinscore_banner: false }, accountActions);
        break;
      case "new_feed":
        setUnreadFeeds(data.unread_feeds, this.props.feedActions);
        break;
      case "broadcast_done":
        setBroadcastStage(EBroadcastStage.None, this.props.chatsActions);
        setFilters(
          chatFiltersSet,
          user,
          providerConfig,
          chatsActions,
          pubnubActions,
          true
        );
        break;
      case "provider_features_update":
        updateProviderConfig(
          {
            features: {
              ...this.props.providerConfig.features,
              ...data.features,
            },
          },
          accountActions
        );
        break;
      case "deletion-scheduled":
        updateUser(
          { deleted_scheduled: true, delete_at: data.delete_at },
          accountActions
        );
        break;
    }
  };

  private handleSendMessage = (messageData: any, force?: boolean) => {
    if (
      (document.visibilityState === "visible" || force) &&
      this.socket &&
      this.socket.readyState === EWebsocketReadyState.Open
    ) {
      this.socket.send(JSON.stringify(messageData));
      console.info(
        "websocket message sent",
        messageData,
        JSON.stringify(messageData)
      );
    } else {
      console.warn(
        "websocket message not sent",
        messageData,
        JSON.stringify(messageData)
      );
    }
  };

  // close is called also after error
  private handleClose = (event: CloseEvent) => {
    myWebsocketEvent.removeListener(this.handleSendMessage);
    clearInterval(this.pingInterval);
    this.reportDisconnected();

    if (event.code === 1000) {
      // Normal Closure
      console.info("websocket closed", event);
    } else {
      // treat everything else as error
      console.warn("websocket closed with error", event);
      this.reconnectSocket();
    }
  };

  private handleVisibilityChange = () => {
    this.reportVisibilityChange();

    if (document.visibilityState === "visible") {
      this.connectSocket();
    } else {
      // this.forceCloseSocket();
    }
  };

  private reportVisibilityChange = () => {
    this.handleSendMessage(
      {
        visibilityState: document.visibilityState,
      },
      true
    );
  };

  private handleNetworkStatusChange = () => {
    if (!navigator.onLine) {
      this.forceCloseSocket();
    }
  };

  private reportConnected = () => {
    clearTimeout(this.reportDisconnectedTimeout);
    this.reportDisconnectedTimeout = null; // reset the value too so we can check in reportDisconnected if we are already reporting

    setIsConnected(this.props.actions);
  };

  private reportDisconnected = () => {
    // if we are already reporting disconnected, do nothing
    if (!this.reportDisconnectedTimeout) {
      this.reportDisconnectedTimeout = setTimeout(() => {
        setIsDisconnected(this.props.actions);
      }, 5000); // 5 seconds delay to avoid flickering
    }
  };

  public render() {
    return null;
  }
}
