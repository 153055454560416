import { handleActions } from "redux-actions";
import { EUserType, IUserInfo } from "../account/account-models";
import { IDictionary, IPopupConfig } from "../models";
import { removeDuplicates } from "../utils/array";
import { ERequestStatus, IGetRequest, requestInit } from "../utils/async";
import { defined } from "../utils/variable-evaluation";
import { IDiscoverActions } from "./discover-actions";
import {
  getDefaultDiscoverFiltersState,
  IDiscoverFilter,
  IGetProfilesConfig,
} from "./discover-helpers";
import {
  EDiscoverPunishmentAlertType,
  IDiscoverAlerts,
} from "./discover-models";

export interface IDiscoverModel {
  isInitialRequest: boolean;
  filtersSet: IDictionary<IDiscoverFilter>;
  getProfiles: IGetRequest<any>;
  profiles: (IUserInfo & { __isImpressionTracked?: boolean })[];
  nextProfilesUrl: string | null;
  rank: number | null;
  alerts: IDiscoverAlerts;
  discoverPopup: IPopupConfig | null;
  getDiscoverCards: IGetRequest<any>;
  discoverCards: IUserInfo[];
  getMarkCardAsVisited: IGetRequest<any>;
  discoverCardsHistory: IUserInfo[];
}

export const discoverStoreInitialState: IDiscoverModel = {
  isInitialRequest: true,
  filtersSet: getDefaultDiscoverFiltersState(),
  getProfiles: requestInit(),
  profiles: [],
  nextProfilesUrl: null,
  rank: null,
  alerts: {
    [EDiscoverPunishmentAlertType.Punishment]: false,
    [EDiscoverPunishmentAlertType.FinalPunishment]: false,
  },
  discoverPopup: null,
  getDiscoverCards: requestInit(),
  discoverCards: [],
  getMarkCardAsVisited: requestInit(),
  discoverCardsHistory: [],
};

/** Reducer */
export const discoverReducer = handleActions<IDiscoverModel, IDiscoverModel>(
  {
    [IDiscoverActions.Type.GET__PROFILES__INIT]: (state, action: any) => {
      const { isInitialRequest, isForceLoader } = action.payload
        .config as IGetProfilesConfig;
      return {
        ...state,
        getProfiles: {
          status: ERequestStatus.Fetching,
        },
        isInitialRequest,
        // listScrollY: isInitialRequest ? 0 : state.listScrollY,
        // profiles: isInitialRequest ? [] : state.profiles,
        profiles: isForceLoader ? [] : state.profiles,
        discoverCards: isInitialRequest ? [] : state.discoverCards,
        discoverCardsHistory: isInitialRequest
          ? []
          : state.discoverCardsHistory,
      };
    },
    [IDiscoverActions.Type.GET__PROFILES__SUCCESS]: (state, action: any) => {
      const { config, res }: { config: IGetProfilesConfig; res: any } =
        action.payload;
      let profiles = [];
      if (config.isInitialRequest) {
        profiles = res.data;
      } else {
        profiles = [...state.profiles, ...res.data];
      }
      profiles = removeDuplicates(profiles, "username");
      return {
        ...state,
        nextProfilesUrl: defined(res.next) ? res.next : null,
        getProfiles: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        profiles,
        alerts: {
          [EDiscoverPunishmentAlertType.Punishment]: res.punishment_alert,
          [EDiscoverPunishmentAlertType.FinalPunishment]:
            res.final_punishment_alert,
        },
        rank: res.rank,
        filtersSet: config.filtersSet,
      };
    },
    [IDiscoverActions.Type.GET__PROFILES__FAILURE]: (state, action: any) => {
      return {
        ...state,
        getProfiles: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_POPUP]: (state, action: any) => {
      return {
        ...state,
        discoverPopup: action.payload,
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_ALERT_CONFIRMED]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [action.payload.type]: false,
        },
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_PROFILE_IMPRESSION_TRACKED]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        profiles: state.profiles.map((profile) => {
          if (profile.username === action.payload) {
            return {
              ...profile,
              __isImpressionTracked: true,
            };
          }
          return profile;
        }),
      };
    },
    [IDiscoverActions.Type.GET__DISCOVER_CARDS__INIT]: (state, action: any) => {
      return {
        ...state,
        getDiscoverCards: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IDiscoverActions.Type.GET__DISCOVER_CARDS__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        getDiscoverCards: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
        discoverCards: [
          ...state.discoverCards,
          ...(action.payload.data || []).filter(
            (c: IUserInfo) =>
              c.type === EUserType.Featured &&
              !c.is_followee &&
              !state.discoverCardsHistory.find(
                (historyCard) => historyCard._id === c._id
              ) &&
              !state.discoverCards.find((card) => card._id === c._id)
          ),
        ],
      };
    },
    [IDiscoverActions.Type.GET__DISCOVER_CARDS__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        getDiscoverCards: {
          error: action.error,
          status: ERequestStatus.Error,
        },
      };
    },
    [IDiscoverActions.Type.GET__MARK_CARD_AS_VISITED__INIT]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        discoverCards: state.discoverCards.filter((c, i) => i !== 0),
        discoverCardsHistory: [
          ...state.discoverCardsHistory,
          state.discoverCards[0],
        ],
        getMarkCardAsVisited: {
          status: ERequestStatus.Fetching,
        },
      };
    },
    [IDiscoverActions.Type.GET__MARK_CARD_AS_VISITED__SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        getMarkCardAsVisited: {
          responseData: action.payload,
          status: ERequestStatus.Fetched,
        },
      };
    },
    [IDiscoverActions.Type.GET__MARK_CARD_AS_VISITED__FAILURE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        getMarkCardAsVisited: {
          error: action.payload,
          status: ERequestStatus.Error,
        },
      };
    },
    [IDiscoverActions.Type.SET__DISCOVER_CARD_IMPRESSION_TRACKED]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        discoverCards: state.discoverCards.map((card) => {
          if (card.username === action.payload) {
            return {
              ...card,
              __isImpressionTracked: true,
            };
          }
          return card;
        }),
      };
    },
  },
  discoverStoreInitialState
);
