import { pushVariable } from "@aparlay/aparlay-web";
import {
  createMediaHash,
  getMediaAvailable,
} from "../common/uploader/media-uploader-helpers";
import { EFileType, IFileWrapper } from "../common/uploader/uploader-helpers";
import { API_ROOT, IS_PROD, MEDIA_ROOT } from "../globals";
import { EPaymentType, IMediaType, IPopupConfig } from "../models";
import { hashData } from "../utils/crypto";
import {
  deleteRequest,
  getRequest,
  globalHeaders,
  patchRequest,
  postRequest,
  putRequest,
} from "../utils/fetch";
import { promisifyXhr } from "../utils/promise";
import { sendWarningMessage } from "../utils/slack";
import { timeStampInMs } from "../utils/time";
import { defined } from "../utils/variable-evaluation";
import { AccountActions } from "./account-actions";
import { purgeUserData } from "./account-helpers";
import {
  EAutoMessageCategory,
  EAutoMessageType,
  EUserType,
  IAutoMessage,
  IOnboardingConfig,
  IOnboardingInitConfig,
  IProviderConfig,
  ISubscriptionInfo,
  IUserInfo,
  IVaultMedia,
} from "./account-models";
import { IGetCreditsForm } from "./get-credits/add-credits-helpers";
import { EOnboardingType } from "./onboarding";

export function setUserOnboarding(
  config: IOnboardingConfig,
  actions: AccountActions
) {
  actions.setUserOnboarding({ config });
}

export function resetUserOnboarding(
  type: EOnboardingType,
  actions: AccountActions
) {
  actions.resetUserOnboarding({ type });
}

export function getUser(actions: AccountActions, isForceLoader?: boolean) {
  actions.initGetUser({ isForceLoader });
  getRequest<IUserInfo>(`${API_ROOT}/v1/users/me`).then((user) => {
    window.username = user.username; // store handy global
    if (defined(window.Rollbar)) {
      // set Rollbar People tracking
      window.Rollbar.configure({
        payload: {
          person: {
            id: user._id, // required
            username: user.username,
            email: user.email,
          },
        },
      });
    }

    // analytics
    pushVariable("userId", user._id);
    pushVariable(
      "userType",
      user.type === EUserType.Featured
        ? "featured"
        : user.is_customer
          ? "purchasedUser"
          : "freeUser"
    );
    hashData(user.email).then(
      (hashedEmail) => {
        console.log("hashedEmail", hashedEmail);
        window.fbq?.("set", "userData", {
          em: hashedEmail,
        });
        window.fbq?.("trackCustom", "EmailSet", {
          email: hashedEmail,
        });
      },
      () => {
        console.error("Failed to hash email");
      }
    );
    if (
      user.type === EUserType.Featured &&
      user.new_fm_onboarding &&
      user.id_verified
    ) {
      window.fbq?.("track", "IdVerified");
    }

    actions.successGetUser(user);
  }, actions.failureGetUser);
}

export function getProviderConfig(actions: AccountActions) {
  actions.initGetProviderConfig();
  getRequest<IProviderConfig>(
    `${API_ROOT}/v1/provider/config?platform=react`
  ).then((providerConfig) => {
    if (!providerConfig.is_guest) {
      actions.successGetProviderConfig(providerConfig);
    } else {
      // TODO: this should be handled by API returning Unauthorized 401 (or 403) instead
      purgeUserData();
    }
  }, actions.failureGetProviderConfig);
}

export function getUsernameAvailable(
  username: string,
  actions: AccountActions
) {
  actions.initGetUsernameAvailable({ username });
  getRequest(`${API_ROOT}/v1/username-available?username=${username}`).then(
    actions.successGetUsernameAvailable,
    actions.failureGetUsernameAvailable
  );
}

// export function user(user, actions: AccountActions) {
//   actions.setUser(user);
// }

export function patchUpdateUser(
  userId: string,
  newValues: Partial<IUserInfo>,
  actions: AccountActions,
  successCallback?: (res: any) => any,
  errorCallback?: (errorRes: any) => any
) {
  actions.initPatchUpdateUser({ newValues });
  patchRequest(`${API_ROOT}/v1/users/${userId}`, newValues).then(
    (res) => {
      successCallback && successCallback(res);
      actions.successPatchUpdateUser({ res, newValues });
    },
    (error) => {
      errorCallback && errorCallback(error);
      actions.failurePatchUpdateUser({ error, newValues });
    }
  );
}

export function resetPatchUpdateUser(actions: AccountActions) {
  actions.resetPatchUpdateUser();
}

export function updateUser(
  newValues: Partial<IUserInfo>,
  actions: AccountActions
) {
  actions.updateUser({ newValues });
}

export function updateProviderConfig(
  newValues: Partial<IProviderConfig>,
  actions: AccountActions
) {
  actions.updateProviderConfig({ newValues });
}

export function deleteUserLogout(
  actions: AccountActions,
  redirectToLogin = true,
  customRedirectUrl?: string
) {
  actions.initDeleteUserLogout();
  deleteRequest(`${API_ROOT}/v1/auth`).then(
    (res) => {
      purgeUserData(redirectToLogin, customRedirectUrl);
      actions.successDeleteUserLogout(res);
    },
    (err) => {
      actions.errorDeleteUserLogout(err);
    }
  );
}

export function getGeoIp(actions: AccountActions) {
  actions.initGetGeoIp();
  getRequest(`${API_ROOT}/v1/geo_ip`).then(
    actions.successGetGeoIp,
    actions.failureGetGeoIp
  );
}

export function postGetCredits(
  paymentType: EPaymentType,
  bodyFormInfo: IGetCreditsForm,
  actions: AccountActions,
  successCallback?: (res: any) => void
) {
  actions.initPostGetCredits({ bodyFormInfo });
  postRequest(`${API_ROOT}/v1/payment`, {
    ...bodyFormInfo,
    model_id:
      paymentType === EPaymentType.PackageSelect
        ? undefined
        : bodyFormInfo.model_id, // for PackageSelect we use model_id just for redirecting back to model chat from the 3DS page, providing it in here would cause api to think this is a subscription
    success_url: `${window.location.origin}/3ds-callback?3ds=success&paymentType=${paymentType}&modelId=${bodyFormInfo.model_id}&amount=${bodyFormInfo.amount}&chatId=${bodyFormInfo.chat_id}&messageId=${bodyFormInfo.message_id}&feedId=${bodyFormInfo.feed_id}`,
    fail_url: `${window.location.origin}/3ds-callback?3ds=error&paymentType=${paymentType}&modelId=${bodyFormInfo.model_id}&amount=${bodyFormInfo.amount}&chatId=${bodyFormInfo.chat_id}&messageId=${bodyFormInfo.message_id}&feedId=${bodyFormInfo.feed_id}`,
  }).then((res: any) => {
    if (res.three_ds) {
      window.open(res.redirect_url, "_self");
    } else if (res.result) {
      actions.successPostGetCredits(res);
      successCallback && successCallback(res);
    } else {
      actions.failurePostGetCredits(res);
    }
  }, actions.failurePostGetCredits);
}

export function resetPostGetCredits(actions: AccountActions) {
  actions.resetPostGetCredits();
}

export function postSendSignInEmail(
  config: IOnboardingInitConfig,
  actions: AccountActions
) {
  let url = `${API_ROOT}/v1/users/sign`;
  if (config.isVerifyResend) {
    url = `${API_ROOT}/v1/users/resend-verify`;
  }
  actions.initPostSendSignInEmail(config);
  postRequest(url, { ...config, web: true, react_flow: true }).then(
    actions.successPostSendSignInEmail,
    actions.failurePostSendSignInEmail
  );
}

export function resetPostSendSignInEmail(actions: AccountActions) {
  actions.resetPostSendSignInEmail();
}

export function setAccountPopup(
  config: IPopupConfig | null,
  actions: AccountActions
) {
  actions.setAccountPopup(config);
}

export function setOnboardingPopup(
  config: IPopupConfig | null,
  actions: AccountActions
) {
  actions.setOnboardingPopup(config);
}

export function resetUpdateUserNotifications(actions: AccountActions) {
  actions.resetUpdateUserNotifications();
}

export function resetUpdateUserProfile(actions: AccountActions) {
  actions.resetUpdateUserProfile();
}

export function getSavedCreditCards(actions: AccountActions) {
  actions.initGetSavedCreditCards();
  getRequest(`${API_ROOT}/v1/cc`).then(
    actions.successGetSavedCreditCards,
    actions.failureGetSavedCreditCards
  );
}

export function deleteSavedCreditCard(
  id: string,
  actions: AccountActions,
  successCallback?: (res: any) => any,
  errorCallback?: (errorRes: any) => any
) {
  actions.initDeleteSavedCreditCard();
  deleteRequest(`${API_ROOT}/v1/cc?id=${id}`).then(
    (res) => {
      successCallback && successCallback(res);
      actions.successDeleteSavedCreditCard(res);
    },
    (errorRes) => {
      errorCallback && errorCallback(errorRes);
      actions.failureDeleteSavedCreditCard(errorRes);
    }
  );
}

export interface IGetSubscriptionsConfig {
  isInitialRequest: boolean;
}

export function getSubscriptions(
  config: IGetSubscriptionsConfig,
  actions: AccountActions
) {
  actions.initGetSubscriptions({ config });
  getRequest(`${API_ROOT}/v1/subscriptions?limit=20&offset=0`).then(
    (res) => actions.successGetSubscriptions({ res, config }),
    actions.failureGetSubscriptions
  );
}

export function getNextSubscriptions(
  nextUrl: string,
  config: IGetSubscriptionsConfig,
  actions: AccountActions
) {
  actions.initGetSubscriptions({ config });
  getRequest(API_ROOT + nextUrl).then(
    (res) => actions.successGetSubscriptions({ res, config }),
    actions.failureGetSubscriptions
  );
}

// export function deleteSubscription(
//   id: string,
//   actions: AccountActions,
//   headerModalActions: HeaderModalActions,
//   callback?: () => void
// ) {
//   actions.initDeleteSubscription();
//   deleteRequest(`${API_ROOT}/v1/subscriptions/${id}`)
//     .then((res) => {
//       actions.successDeleteSubscription(res);
//       toggleHeaderMenu('', headerModalActions); // close menu
//       // reload subscriptions
//       getSubscriptions({ isInitialRequest: true }, actions);
//       callback && callback();
//     }, actions.failureDeleteSubscription);
// }

export function deleteVideoGreeting(actions: AccountActions) {
  actions.initDeleteVideoGreeting();
  deleteRequest(`${API_ROOT}/v1/users/greeting`).then((res) => {
    getUser(actions); // reload user
    actions.successDeleteVideoGreeting(res);
  }, actions.failureDeleteVideoGreeting);
}

export function setManageSubscriptionsEditing(
  isEditing: boolean,
  actions: AccountActions
) {
  actions.setManageSubscriptionsEditing(isEditing);
}

export function toggleManageSubscriptionsSubSelection(
  sub: ISubscriptionInfo,
  actions: AccountActions
) {
  actions.toggleManageSubscriptionsSubSelection(sub);
}

export function toggleManageSubscriptionsAllSubsSelection(
  actions: AccountActions
) {
  actions.toggleManageSubscriptionsAllSubsSelection();
}

export function deleteSubscriptions(
  // isSelectAll: boolean,
  subUserIds: string[],
  actions: AccountActions
) {
  // const key = isSelectAll ? 'except_ids' : 'ids';
  const key = "ids";
  const value = subUserIds.join(",");
  actions.initDeleteSubscriptions();
  deleteRequest(`${API_ROOT}/v1/subscriptions?${key}=${value}`).then((res) => {
    actions.successDeleteSubscriptions(res);
    // reload subscriptions
    getSubscriptions({ isInitialRequest: true }, actions);
  }, actions.failureDeleteSubscriptions);
}

export function resetDeleteSubscriptions(actions: AccountActions) {
  actions.resetDeleteSubscriptions();
}

// export function setPopupBookmark(actions: AccountActions) {
//   setAccountPopup(
//     {
//       type: EPopupAccountType.PopupBookmark,
//       props: {
//         type: isSafariMobile ? EPopupBookmarkType.Ios : EPopupBookmarkType.Android,
//         onClose: () => {
//           setAccountPopup(null, actions);
//         }
//       }
//     },
//     actions
//   );
// }

// export function setPopupOldSafari(actions: AccountActions) {
//   setAccountPopup(
//     {
//       type: EPopupAccountType.PopupOldSafari,
//       props: {
//         onClose: () => {
//           setAccountPopup(null, actions);
//         }
//       }
//     },
//     actions
//   );
// }

// export function setPopupsOnLogin(user: IUserInfo, accountActions: AccountActions) {
//   if (window.innerWidth < FROM_TABLET && defined(user) && defined(user.username)) {
//     setPopupBookmark(accountActions);
//     // const version = iosVersion();
//     // if (
//     //   isSafariMobile &&
//     //   defined(version) &&
//     //   (version[0] < 13 || (version[0] === 13 && version[1] < 2))
//     // ) {
//     //   setPopupOldSafari(accountActions);
//     // }
//   }
// }

export function patchUserRandomAvatar(actions: AccountActions) {
  actions.initPatchUserRandomAvatar();
  patchRequest(`${API_ROOT}/v1/users/random_avatar`, {}).then(
    actions.successPatchUserRandomAvatar,
    actions.failurePatchUserRandomAvatar
  );
}

export function getAutoMessages(actions: AccountActions) {
  actions.initGetAutoMessages();
  getRequest(`${API_ROOT}/v1/auto-messages`).then(
    actions.successGetAutoMessages,
    actions.failureGetAutoMessages
  );
}

/**
 * Add new auto message on client side.
 * In case there is media also upload the media here.
 * This change will be applied on next submit.
 */
export async function postAutoMessage(
  category: EAutoMessageCategory,
  autoMessage: IAutoMessage,
  mediaFileWrapper: IFileWrapper | null,
  vaultMedia: IVaultMedia | null,
  user: IUserInfo,
  actions: AccountActions
) {
  const __clientSideSendId = timeStampInMs();

  if (autoMessage.type === EAutoMessageType.Text) {
    actions.initPostAutoMessage({
      category,
      clientSideAutoMessage: {
        ...autoMessage,
        __clientSideSendId,
      },
    });
    actions.successPostAutoMessage({
      category,
      response: {
        data: {
          ...autoMessage,
          __clientSideSendId,
          _id: "_" + __clientSideSendId,
        },
      },
      __clientSideSendId,
    });
  } else if (mediaFileWrapper) {
    const md5Hash = createMediaHash(mediaFileWrapper!.file, user);

    // NOTE: In Feed Uploader there is only 1 request to upload to /v1/media
    // and it will automatically upload and create a new feed post
    // that's why we don't do this in feed, it's handled by API
    const response = await getMediaAvailable(md5Hash, autoMessage.price! > 0);
    const isAvailable = response.status === true;

    if (isAvailable) {
      // this media was already uploaded before

      const clientSideMedia: IMediaType = {
        public_id: md5Hash,
        th: mediaFileWrapper!.elm.src,
        st: mediaFileWrapper!.elm.src,
        rt: mediaFileWrapper!.elm.src,
        width: mediaFileWrapper!.width,
        height: mediaFileWrapper!.height,
        isClientSide: true,
        uploadProgress: 100,
        loaded: mediaFileWrapper!.file.size,
        total: mediaFileWrapper!.file.size,
      };

      if (mediaFileWrapper!.type === EFileType.Video) {
        clientSideMedia.video_url = mediaFileWrapper!.elm.src;
        clientSideMedia.video_length = mediaFileWrapper!.duration;
      }

      actions.initPostAutoMessage({
        category,
        clientSideAutoMessage: {
          ...autoMessage,
          __clientSideSendId,
          media: clientSideMedia,
        },
      });

      const { width, height, video_length, media } = response;
      actions.successPostAutoMessage({
        category,
        response: {
          data: {
            ...autoMessage,
            __clientSideSendId,
            _id: "_" + __clientSideSendId,
            media_id: md5Hash,
            media: {
              ...clientSideMedia,
              th: media ? media.th : clientSideMedia.th,
              st: media ? media.st : clientSideMedia.st,
              rt: media ? media.rt : clientSideMedia.rt,
              sr: media ? media.sr : clientSideMedia.sr,
              width,
              height,
              video_length,
              video_url: media ? media.video_url : clientSideMedia.video_url,
              isClientSide: media ? false : true,
            },
          },
        },
        __clientSideSendId,
      });
    } else {
      // need to upload first

      const uploadFormData = new FormData();
      uploadFormData.append("file", mediaFileWrapper!.file);
      uploadFormData.append("md5", md5Hash);
      uploadFormData.append(
        "type",
        autoMessage.price! > 0 ? "paid_message" : "message"
      );

      // POST file request using XMLHttpRequest
      // because Fetch API is still lacking the support for request progression
      // (https://fetch.spec.whatwg.org/#fetch-api)
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${MEDIA_ROOT}/v1/media`);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.setRequestHeader("cache-control", "no-cache"); // Make sure brain dead iOS safari won't cache this https://stackoverflow.com/questions/12506897/is-safari-on-ios-6-caching-ajax-results
      Object.entries(globalHeaders()).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      const handleProgress = (
        progress: number,
        loaded: number,
        total: number
      ) => {
        // update upload progress
        actions.updatePostAutoMessageProgress({
          category,
          progress,
          loaded,
          total,
          __clientSideSendId,
        });
      };

      const handleSuccess = ({ response }: any) => {
        // send the message after upload
        const { width, height, video_length, media } = response;
        actions.successPostAutoMessage({
          category,
          response: {
            data: {
              ...autoMessage,
              __clientSideSendId,
              _id: "_" + __clientSideSendId,
              media_id: md5Hash,
              media: {
                ...clientSideMedia,
                public_id: media.public_id,
                th: media.th,
                st: media.st,
                rt: media.rt,
                sr: media.sr,
                uploadProgress: 100,
                loaded: mediaFileWrapper!.file.size,
                total: mediaFileWrapper!.file.size,
                width,
                height,
                video_length,
                video_url: media.video_url,
                isClientSide: false,
              },
            },
          },
          __clientSideSendId,
        });
      };

      const handleError = ({ status, response, isAbort }: any) => {
        if (!isAbort) {
          let errorMessage =
            "This file failed to upload. Please check your network connection and try again.";
          if (defined(response.message)) {
            errorMessage = response.message;
          }
          sendWarningMessage(
            `\`${
              window.username
            }\` Account AutoMessagesUploader failed to upload: ${errorMessage} ${status} ${JSON.stringify(
              response
            )} ${JSON.stringify(mediaFileWrapper)} file mimeType: ${
              mediaFileWrapper?.file?.type
            } md5Hash: ${md5Hash}`,
            IS_PROD && status >= 400 ? "#api-status" : "#web-logs"
          );
        }
        actions.failurePostAutoMessage({
          category,
          isAbort,
          errorResponse: response,
          __clientSideSendId,
        });
      };

      const clientSideMedia: IMediaType = {
        public_id: md5Hash,
        th: mediaFileWrapper!.elm.src,
        st: mediaFileWrapper!.elm.src,
        rt: mediaFileWrapper!.elm.src,
        width: mediaFileWrapper!.width,
        height: mediaFileWrapper!.height,
        isClientSide: true,
        uploadProgress: 0,
        loaded: 0,
        total: mediaFileWrapper!.file.size,
        abortUpload: () => {
          xhr.abort();
        },
      };

      if (mediaFileWrapper!.type === EFileType.Video) {
        clientSideMedia.video_url = mediaFileWrapper!.elm.src;
        clientSideMedia.video_length = mediaFileWrapper!.duration;
      }

      actions.initPostAutoMessage({
        category,
        clientSideAutoMessage: {
          ...autoMessage,
          __clientSideSendId,
          media: clientSideMedia,
        },
      });

      promisifyXhr(xhr, handleProgress).then(handleSuccess, handleError);
      xhr.send(uploadFormData);
    }
  } else if (vaultMedia) {
    const clientSideMedia: IMediaType = {
      public_id: vaultMedia.public_id,
      media_id: vaultMedia.media_id,
      th: vaultMedia.th,
      st: vaultMedia.st,
      rt: vaultMedia.rt,
      width: vaultMedia.width,
      height: vaultMedia.height,
      video_url: vaultMedia.video_url,
      video_length: vaultMedia.video_length,
      isClientSide: true,
      uploadProgress: 100,
      loaded: 0,
      total: 0,
    };

    actions.initPostAutoMessage({
      category,
      clientSideAutoMessage: {
        ...autoMessage,
        __clientSideSendId,
        media: clientSideMedia,
      },
    });

    actions.successPostAutoMessage({
      category,
      response: {
        data: {
          ...autoMessage,
          __clientSideSendId,
          _id: "_" + __clientSideSendId,
          media_id: vaultMedia.media_id,
          public_id: vaultMedia.public_id,
          media: clientSideMedia,
        },
      },
      __clientSideSendId,
    });
  }
}

/**
 * Mark auto message for deletion on client side.
 * This change will be applied on next submit.
 */
export function deleteAutoMessage(
  category: EAutoMessageCategory,
  autoMessageId: string,
  actions: AccountActions
) {
  actions.initDeleteAutoMessage({
    category,
    autoMessageId,
  });
  actions.successDeleteAutoMessage({
    category,
    response: null,
    autoMessageId,
  });
}

/**
 * Submit new list of auto messages to category
 */
export function putAutoMessages(
  category: EAutoMessageCategory,
  autoMessages: IAutoMessage[],
  actions: AccountActions
) {
  // transform data for API
  const autoMessagesToSend = autoMessages
    .filter((m) => defined(m._id)) // filter messages that are not done uploading yet (user is warned about this with AutoMessagesLeaveEmptyConfirmationPopup)
    .map((m) => ({
      ...m,
      __clientSideSendId: undefined, // don't send local ids
      _id: m._id!.startsWith("_") ? undefined : m._id, // don't send local ids
      media: undefined, // don't send local media
      width: m.media ? m.media.width : undefined,
      height: m.media ? m.media.height : undefined,
      video_length: m.media ? m.media.video_length : undefined,
    }));

  actions.initPutAutoMessages({
    category,
    autoMessages,
  });
  putRequest(`${API_ROOT}/v1/auto-messages`, {
    category,
    auto_messages: autoMessagesToSend,
  }).then(
    (response) => {
      actions.successPutAutoMessages({
        category,
        autoMessages,
        response,
      });
    },
    (errorResponse) => {
      actions.failurePutAutoMessages({
        category,
        autoMessages,
        errorResponse,
      });
    }
  );
}

export function deleteAutoMessages(
  category: EAutoMessageCategory,
  actions: AccountActions
) {
  actions.initDeleteAutoMessages({
    category,
  });
  putRequest(`${API_ROOT}/v1/auto-messages`, {
    category,
    auto_messages: [],
  }).then(
    (response) => {
      actions.successDeleteAutoMessages({
        category,
        response,
      });
    },
    (errorResponse) => {
      actions.failureDeleteAutoMessages({
        category,
        errorResponse,
      });
    }
  );
}

export function resetAutoMessages(actions: AccountActions) {
  actions.resetAutoMessages();
}

export function postUserTag(tag: string, actions: AccountActions) {
  actions.initPostUserTag({
    tag,
  });
  postRequest(`${API_ROOT}/v1/users/tags`, {
    tag,
  }).then(
    (response) => {
      actions.successPostUserTag({
        tag,
        response,
      });
    },
    (errorResponse) => {
      actions.failurePostUserTag({
        tag,
        errorResponse,
      });
    }
  );
}

export function getMyPurchases(actions: AccountActions) {
  actions.initGetMyPurchases();
  getRequest(`${API_ROOT}/v1/message/paid?limit=20&offset=0`).then(
    actions.successGetMyPurchases,
    actions.failureGetMyPurchases
  );
}

export function getMyPurchasesNext(nextUrl: string, actions: AccountActions) {
  actions.initGetMyPurchasesNext();
  getRequest(`${API_ROOT}${nextUrl}`).then(
    actions.successGetMyPurchasesNext,
    actions.failureGetMyPurchasesNext
  );
}

export function getIdVerification(actions: AccountActions) {
  actions.initGetIdVerification();
  getRequest(`${API_ROOT}/v1/users/id-verification`).then(
    actions.successGetIdVerification,
    actions.failureGetIdVerification
  );
}

export function getVaultMedia(chatId: string | null, actions: AccountActions) {
  actions.initGetVaultMedia();
  getRequest(
    `${API_ROOT}/v1/media?limit=20&offset=0${chatId ? `&chat_id=${chatId}` : ""}`
  ).then(actions.successGetVaultMedia, actions.failureGetVaultMedia);
}

export function getVaultMediaNext(nextUrl: string, actions: AccountActions) {
  actions.initGetVaultMediaNext();
  getRequest(`${API_ROOT}${nextUrl}`).then(
    actions.successGetVaultMediaNext,
    actions.failureGetVaultMediaNext
  );
}

export function deleteVaultMedia(mediaIds: string[], actions: AccountActions) {
  actions.initDeleteVaultMedia();
  deleteRequest(`${API_ROOT}/v1/media?ids=${mediaIds.join(",")}`).then(
    (response) => {
      actions.successDeleteVaultMedia({
        mediaIds,
        response,
      });
    },
    actions.failureDeleteVaultMedia
  );
}
